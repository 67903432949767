import React from "react";

import {
    Box,
    // Customizable Area Start
    Button,
    Divider,
    Typography,
    DialogActions,
    DialogContent,
    IconButton,
    DialogTitle,
    Dialog
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { PieChart, Pie, Tooltip, Cell } from "recharts";
import CloseIcon from '@mui/icons-material/Close';
import ManageSubscriptionController, {
    Props,
} from "./ManageSubscriptionController.web";
import { BackArrowBlack, check, checkImg, publish, sad } from "./assets";
import HeaderDropdown from "../../navigationmenu/src/HeaderDropdown.web";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
interface DataItem {
    name: string;
    value: number;
    color: string;
}

const data: DataItem[] = [
    { name: 'Photos', value: 50, color: '#8833FF' },
    { name: 'Videos', value: 10, color: '#F59E0B' },
    { name: 'Recipes', value: 10, color: '#60A5FA' },
    { name: 'Free Space', value: 30, color: '#EC4899' },
];

const usedData = data.filter(item => item.name !== 'Free Space');
const totalUsed = usedData.reduce((acc, item) => acc + item.value, 0);

const renderCustomLabel = ({ cx, cy }: any) => {
    return (
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#333" className="middlepieText">
            {`${totalUsed} GB`}
        </text>
    );
};
// Customizable Area End

export default class ManageSubscription extends ManageSubscriptionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ManageSubscriptionStyle>
                <HeaderDropdown data-test-id="headerComponent" navigation={this.props.navigation} id={""} />
                <Box style={{ padding: '30px', marginLeft: '50px', marginRight: '20px' }}>
                    <Breadcrumbs style={{ marginLeft: '70px' }} separator={<NavigateNextIcon fontSize="small" />}>
                        <Button className="breadcrumbsButton" color="inherit" data-test-id="breadcrumbsButton">
                            Settings
                        </Button>
                        <Button className="breadcrumbsButton" color="inherit" data-test-id="breadcrumbsButton" onClick={this.handleSubscription}>
                            Subscription
                        </Button>
                        <Typography className="breadcrumbsText">
                            Manage my subscription
                        </Typography>
                    </Breadcrumbs>
                    <Box style={{ display: 'flex', marginTop: '15px', marginLeft: '1%' }}>
                        <Button className="backButton" data-test-id="backButton" onClick={this.handleSubscription}>
                            <img style={{ color: 'black' }} src={BackArrowBlack} alt="Back" />
                        </Button>
                        <Typography className="titleTypography">Manage my subscription</Typography>
                    </Box>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '100px', gap: '50px' }}>
                    <Box style={{ width: '965px' }}>
                        <Box className="pieChartMainDiv">
                            <Box className="individualsBox">
                                <Typography className="individualsText">{this.state.planName}</Typography>
                                <Typography className="planText">plan</Typography>
                            </Box>
                            <Box className="pieBox">
                                <PieChart width={320} height={280}>
                                    <Tooltip />
                                    <Pie
                                        data={data}
                                        dataKey="value"
                                        outerRadius={100}
                                        innerRadius={75}
                                        labelLine={false}
                                        label={renderCustomLabel}
                                    >
                                        {data.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={entry.color} onClick={() => this.handleCellClick(entry.color)} />
                                        ))}
                                    </Pie>
                                </PieChart>
                                <Box style={{ marginRight: '10px' }}>
                                    {data.map((entry, index) => (
                                        <Box key={`data-${index}`} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                            <button onClick={() => this.handleCellClick(entry.color)} style={{ width: '20px', height: '20px', borderRadius: '85px', backgroundColor: entry.color, marginRight: '10px', border: 'none' }} />
                                            <Typography>
                                                <span style={{
                                                    fontWeight: 700,
                                                    fontFamily: "Poppins",
                                                    fontSize: "18px",
                                                    lineHeight: "12px",
                                                    color: "#1C2110",
                                                }}>
                                                    {entry.name}</span>
                                                <span style={{
                                                    fontWeight: 400,
                                                    fontFamily: "Poppins",
                                                    fontSize: "18px",
                                                    lineHeight: "26px",
                                                    color: "#1C2110",
                                                }}>: {entry.value} GB used </span>
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                            <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '30px', marginTop: '-55px', alignItems: 'center' }}>
                                <Typography style={{
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    lineHeight: "24px",
                                    color: "#1C2110",
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <img src={check} style={{ marginRight: '5px' }} /> Total storage in your plan:
                                    <span style={{
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                        fontSize: "14px",
                                        lineHeight: "24px",
                                        color: "#1C2110",
                                    }}> {this.state.storage} {this.state.storageUnit} </span></Typography>
                                <Typography style={{
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                    fontSize: "30px",
                                    lineHeight: "40px",
                                    letterSpacing: '-0.5%',
                                    color: "#1C2110",
                                }}>
                                    ${this.state.planAmount}
                                    <span style={{
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        lineHeight: "18px",
                                        color: "#78716C",
                                    }}>/{this.state.interval}</span></Typography>
                            </Box>
                        </Box>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '20%', marginTop: '5px' }}>
                            <Button className="changePlanBtn" data-test-id="changePlanBtn" onClick={this.handleChangeModal}>Change my subscription plan</Button>
                            <Button className="cancelPlanBtn" data-test-id="cancelPlanBtn" onClick={this.handleCancelModal}>Cancel my subscription</Button>
                        </Box>
                    </Box>
                    <Box className="benefitsBox">
                        <Typography className="benefitTitle">Benefits of {this.state.planName} Plan</Typography>
                        <Box style={{ marginTop: '30px' }}>
                            {this.state.benefits && (
                                <div>
                                    <Box className="textBox">
                                        <Typography className="storageText">
                                            <div dangerouslySetInnerHTML={{ __html: this.state.benefits }} />
                                        </Typography>
                                    </Box>
                                    <Divider style={{ backgroundColor: '#8CA74B', marginTop: '15px', marginBottom: '15px' }} />
                                </div>
                            )}
                        </Box>
                    </Box>
                </Box>

                <Dialog open={this.state.isCancelModelOpen} onClose={this.handleCloseCancelModal} data-test-id="isCancelModelOpen" PaperProps={{
                    style: {
                        height: 655,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderRadius: 8,
                        borderBottomRightRadius: 50,
                    },
                }} >
                    <DialogTitle style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins' }}>
                        <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Cancel subscription</Typography>
                        <IconButton
                            style={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                color: '#666',
                            }}
                            onClick={this.handleCloseCancelModal}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '20px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Box style={{
                                backgroundColor: '#8CA74B',
                                textAlign: 'center',
                                height: '130px',
                                borderRadius: '80px',
                                display: 'flex',
                                justifyContent: 'center',
                                width: '131px',
                                marginTop: '20px',
                                alignItems: 'center'
                            }}>
                                <img src={sad} style={{ height: '65px', width: '65px', color: 'white' }} />
                            </Box>
                        </div>
                        <Box style={{ textAlign: 'center', margin: '40px 10px 20px 10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110', marginTop: '20px', alignItems: 'center', width: '400px' }}>Do you want to cancel your subscription plan?</Typography>
                            <Typography style={{ width: '450px', fontFamily: 'Poppins', fontWeight: 400, fontSize: 16, color: '#1C2110', marginTop: '20px', alignItems: 'center', }}>
                                {moment(this.state.subscriptionEndDate).isValid()
                                    ? `You will have full access to all features and functionalities of MyKinships until ${(moment(this.state.subscriptionEndDate).format('MMMM Do, YYYY'))}.`
                                    : 'You will have full access to all features and functionalities of MyKinships until .'}
                            </Typography>
                        </Box>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 30, margin: '20px 10px 20px 10px' }}>
                        <Button data-test-id="handleCancelSuccessModal" onClick={this.handleCancelSuccessModal} style={{
                            border: '1px solid #C2E176',
                            backgroundColor: '#F8FFE7',
                            width: '233px',
                            height: '56px',
                            fontSize: "16px",
                            fontWeight: 700,
                            textTransform: "none",
                            fontFamily: "Poppins",
                            lineHeight: "24px",
                            color: '#8CA74B'
                        }}>Cancel my subscription</Button>
                        <Button onClick={this.handleCloseCancelModal} style={{
                            backgroundColor: '#8CA74B',
                            width: '233px',
                            height: '56px',
                            fontSize: "16px",
                            fontWeight: 700,
                            textTransform: "none",
                            fontFamily: "Poppins",
                            lineHeight: "24px",
                            color: 'white'
                        }}>No, keep my subscription</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    data-test-id="isSuccessCancelModalOpen"
                    className="dialogContainer"
                    open={this.state.isSuccessCancelModalOpen}
                    onClose={this.handleCloseCancelSuccessModal}
                    PaperProps={{
                        style: {
                            position: 'relative',
                            height: 'auto',
                            width: '510px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            borderRadius: 8,
                            borderBottomRightRadius: 50,
                        },
                    }}
                >
                    <DialogTitle>
                        <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Cancel subscription</Typography>
                        <IconButton
                            data-test-id='deleteIcon'
                            style={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: '#666',
                            }}
                            onClick={this.handleCloseCancelSuccessModal}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogContent style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Box style={{
                                marginTop: '20px',
                                textAlign: 'center',
                                backgroundColor: '#34D399',
                                height: '130px',
                                width: '130px',
                                borderRadius: '80px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <img src={checkImg} style={{ height: '65px', width: '65px' }} />
                            </Box>
                        </div>
                        <Box style={{ textAlign: 'center', margin: '40px 10px 20px 10px' }}>
                            <Typography style={{
                                fontFamily: "Poppins",
                                fontWeight: 700,
                                fontSize: "20px",
                                lineHeight: "28px",
                                color: "#1C2110",
                                alignItems: 'center',
                            }} >Your subscription was cancelled</Typography>
                            <Typography style={{
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#1C2110",
                                alignItems: 'center',
                                marginTop: '20px',
                                marginBottom: '20px'
                            }}>Your subcription to <span style={{
                                fontFamily: "Poppins",
                                fontWeight: 700,
                                fontSize: "18px",
                                lineHeight: "26px",
                                color: "#1C2110",
                                alignItems: 'center',
                            }}>{this.state.planName} </span> plan was cancelled successfully. You will find all the details in the confirmation e-mail. </Typography>
                        </Box>
                    </DialogContent>
                </Dialog>

                <Dialog className="dialogContainer" data-test-d="handleChangeModal" open={this.state.isSubscriptionChangedModalOpen} onClose={this.handleCloseChangeModal} PaperProps={{
                    style: {
                        position: 'relative',
                        height: 'auto',
                        width: '680px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderRadius: 8,
                        borderBottomRightRadius: 50,
                    }
                }} >
                    <DialogTitle>
                        <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Change my subscription</Typography>
                        <IconButton data-test-id='deleteCloseIcon' onClick={this.handleCloseChangeModal} style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#666',
                        }}><CloseIcon /></IconButton>
                        <Typography style={{
                            color: '#57534E',
                            fontSize: "16px",
                            fontWeight: 400,
                            fontFamily: "Poppins",
                            lineHeight: "24px",
                            marginTop: '15px'
                        }}>Choose your new subscription plan and enjoy your benefits.</Typography>
                    </DialogTitle>
                    <Divider />
                    <DialogBoxContent className="dialogContainerMain">
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Box style={{ display: 'flex', margin: '20px 0 20px 0', padding: '5px' }}>
                                {this.state.plans.map((plan: any) => (
                                    <Box
                                        key={plan.plan_id}
                                        className="planConatiner"
                                        onClick={() => this.handleBoxClick(plan)}
                                        style={{
                                            ...this.state.selectedPlan === plan ?
                                                { border: '1px solid #C2E176', backgroundColor: '#F8FFE7' } :
                                                { border: '1px solid #D6D3D1' },
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <Typography className="individualsTitle">{plan.product_name}</Typography>
                                        <Typography className="storageDialogText">
                                            <img src={check} style={{ marginRight: '8px' }} />{plan.product_name}
                                        </Typography>
                                        <Typography className="priceTag">${(plan.amount / 100).toFixed(2)} <span className="monthTag">/{plan.interval}</span></Typography>
                                    </Box>
                                ))}
                            </Box>
                        </div>
                    </DialogBoxContent>
                    <Divider />
                    <DialogActions style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 30, margin: '20px 10px 20px 10px' }}>
                        <Button onClick={this.handleCloseChangeModal} style={{
                            border: '1px solid #C2E176',
                            backgroundColor: '#F8FFE7',
                            width: '300px',
                            height: '56px',
                            fontSize: "16px",
                            fontWeight: 700,
                            textTransform: "none",
                            fontFamily: "Poppins",
                            lineHeight: "24px",
                            color: '#8CA74B'
                        }}>Cancel</Button>
                        <Button onClick={this.handleSuccessChangeModal} data-test-id="handleSuccessChangeModalBtn" style={{
                            backgroundColor: '#8CA74B',
                            width: '300px',
                            height: '56px',
                            fontSize: "16px",
                            fontWeight: 700,
                            textTransform: "none",
                            fontFamily: "Poppins",
                            lineHeight: "24px",
                            color: 'white'
                        }}>Change my subscription</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    data-test-id="handleSuccessCloseChangeModal"
                    className="dialogContainer"
                    onClose={this.handleSuccessCloseChangeModal}
                    open={this.state.isSubscriptionSuccessModalOpen}
                    PaperProps={{
                        style: {
                            position: 'relative',
                            height: 'auto',
                            width: '510px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            borderRadius: 8,
                            borderBottomRightRadius: 50,
                        },
                    }}
                >
                    <DialogTitle>
                        <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Subscription changed</Typography>
                        <IconButton onClick={this.handleSuccessCloseChangeModal}
                            data-test-id='deleteIcon'
                            style={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: '#666',
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogContent style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Box style={{
                                textAlign: 'center',
                                backgroundColor: '#34D399',
                                borderRadius: '80px',
                                height: '130px',
                                width: '130px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '20px'
                            }}>
                                <img src={publish} style={{ color: 'white', height: '65px', width: '65px' }} />
                            </Box>
                        </div>
                        <Box style={{ textAlign: 'center', margin: '40px 10px 20px 10px' }}>
                            <Typography style={{
                                fontFamily: "Poppins",
                                fontWeight: 700,
                                fontSize: "20px",
                                lineHeight: "28px",
                                color: "#1C2110",
                                alignItems: 'center',
                            }} >Subscription changed</Typography>
                            <Typography style={{
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#1C2110",
                                alignItems: 'center',
                                marginTop: '20px',
                                marginBottom: '20px'
                            }}>Your have upgraded your subscription from {this.state.planName} to {this.state.planName} plan. ${(this.state.amount / 100).toFixed(2)} will be charged your next billing date to your selected payment method. </Typography>
                        </Box>
                    </DialogContent>
                </Dialog>

            </ManageSubscriptionStyle >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const DialogBoxContent = styled("div")({
    "& .individualsTitle": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
        marginBottom: '5px'
    },
    "& .planConatiner": {
        padding: '15px',
        margin: '0 8px 0 8px',
        borderRadius: '8px',
        border: '1px solid #D6D3D1'
    },
    "& .dialogContainerMain": {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    "& .storageDialogText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        color: "#1C2110",
        alignItems: 'center',
        display: 'flex',
        marginBottom: '8px'
    },
    "& .priceTag": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "32px",
        color: "#1C2110",
        letterSpacing: "-0.5%",
    },
    "& .monthTag": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: '#78716C'
    }
});

const ManageSubscriptionStyle = styled("div")({
    "& .breadcrumbsButton": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#57534E",
        "& .MuiButton-label": {
            textTransform: "none",
        },
    },
    "& .breadcrumbsText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#1C2110",
    },
    "& .titleTypography": {
        fontWeight: 700,
        fontFamily: "Poppins",
        lineHeight: "40px",
        fontSize: "30px",
        color: "#1C2110",
        letterSpacing: "-0.5%",
    },
    "& .textBox": {
        display: 'flex'
    },
    "& .pieChartMainDiv": {
        borderRadius: '24px',
        marginLeft: '20%',
        padding: '20px',
        boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.07)",
    },
    "& .benefitsBox": {
        width: '550px',
        marginRight: '15%',
        marginBottm: '10%'
    },
    "& .individualsText": {
        fontWeight: 700,
        fontFamily: "Poppins",
        fontSize: "30px",
        lineHeight: "40px",
        color: "#1C2110",
        letterSpacing: "-0.5%",
        marginTop: '25px'
    },
    "& .planText": {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "24px",
        lineHeight: "32px",
        color: "#1C2110",
        letterSpacing: "-0.5%",
    },
    "& .individualsBox": {
        padding: '10px',
    },
    "& .pieBox": {
        display: 'flex',
        alignItems: 'center',
        marginTop: '-25px',
        padding: '10px'
    },
    "& .middlepieText": {
        fontWeight: 700,
        fontFamily: "Poppins",
        fontSize: "24px",
        lineHeight: "32px",
        color: "#1C2110",
        letterSpacing: "-0.5%",
    },
    "& .usedText": {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "22px",
        color: "#000000",
    },
    "& .benefitTitle": {
        fontWeight: 700,
        fontFamily: "Poppins",
        fontSize: "24px",
        lineHeight: "32px",
        color: "#1C2110",
        letterSpacing: "-0.5%",
    },
    "& .spanText": {
        fontWeight: 700,
        fontFamily: "Poppins",
        fontSize: "18px",
        lineHeight: "26px",
        color: "#5D6063",
        letterSpacing: "-0.5%",
    },
    "& .storageText": {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "18px",
        lineHeight: "26px",
        color: "#1C2110",
    },
    "& .changePlanBtn": {
        fontWeight: 700,
        fontFamily: "Poppins",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#557C29",
        textTransform: 'none'
    },
    "& .cancelPlanBtn": {
        fontWeight: 700,
        fontFamily: "Poppins",
        fontSize: "16px",
        lineHeight: "24px",
        color: '#DC2626',
        textTransform: 'none'
    },

});
// Customizable Area End
