import React, { Component } from 'react';
import { styled, Box, Icon } from "@material-ui/core";
import { Logo } from '../../blocks/signuplogin/src/assets';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { NavLink } from 'react-router-dom';

interface HeaderProps {
    onLoginClick: () => void;
    onRegisterClick: () => void;
    getHeaderData: (data: string) => void;
}

class Header extends Component<HeaderProps> {
    render() {
        const { onLoginClick, onRegisterClick, getHeaderData } = this.props;

        return (
            <HeaderStyle>
                <Box className="mainContainer">
                    <Box className='leftContainer'>
                        <Box className='logoContainer' >
                        <NavLink exact to="/" activeClassName='active'><img className='imgLogo'  src={Logo} alt="Logo" /></NavLink>
                        </Box>
                        <Box className='menuContainer'>
                            <NavLink to="/PhotosPage" style={{marginLeft:'25px'}} className='MenuLink' activeClassName='active' onClick={()=>{getHeaderData("Family Photo Storage");sessionStorage.setItem('tab','Family Photo Storage')}}>Photos</NavLink>
                            <NavLink to="/MomentsPage" className='MenuLink' activeClassName='active' onClick={()=>{getHeaderData("Immortalizing Family Moments");sessionStorage.setItem('tab','Immortalizing Family Moments')}}>Moments</NavLink>
                            <NavLink to="/FamilyTreePage" className='MenuLink' activeClassName='active' onClick={()=>{getHeaderData("Family Tree");sessionStorage.setItem('tab','Family Tree')}}>Family Tree</NavLink>
                            <NavLink to="/RecipesPage" className='MenuLink' activeClassName='active' onClick={()=>{ getHeaderData("Family Recipe Preservation");sessionStorage.setItem('tab','Family Recipe Preservation')}}>Recipes</NavLink>
                            <NavLink to="/Subscriptionbilling" className='MenuLink' activeClassName='active'>Plans and Pricing</NavLink>
                            <NavLink to="/ContactusPage" className='MenuLink' activeClassName='active'>Contact Us</NavLink>
                        </Box>
                    </Box>
                    {!localStorage.getItem("token") && (
                        <Box className='actionBtn'>
                            <NavLink to="/Signup" className='registerLink' onClick={onRegisterClick}>Register</NavLink>
                            <NavLink to="/login" className='loginLink' onClick={onLoginClick}>
                                <Icon><PersonOutlineIcon /></Icon> Log in
                            </NavLink>
                        </Box>
                    )}
                </Box>
            </HeaderStyle>
        );
    }
}

const HeaderStyle = styled("div")({
    "& .mainContainer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "10px",
      borderBottom: "1px solid #E7E5E4",
      height: "70px",
      overflowX: "auto",
      "@media (max-width: 768px)": {
        flexDirection: "column",
        height: "auto", 
        alignItems: "flex-start",
      },
    },
    "& .leftContainer": {
      display: "flex",
      alignItems: "center",
      "@media (max-width: 768px)": {
        width: "100%",
        justifyContent: "space-between",
      },
    },
    "& .logoContainer": {
      marginRight: "auto",
      "@media (max-width: 768px)": {
        marginRight: "0",
      },
    },
    "& .menuContainer": {
      display: "flex",
      alignItems: "center",
      "@media (max-width: 768px)": {
        flexWrap: "wrap", 
        justifyContent: "center",
        marginTop: "10px",
        width: "100%",
      },
      "@media (max-width: 486px)": {
        flexDirection: "column", 
        alignItems: "center",
      },
    },
    "& .MenuLink": {
      fontFamily: "Poppins",
      fontWeight: 700,
      fontSize: "14px",
      color: "#44403C",
      margin: "10px",
      textDecoration: "none",
      "&.active": {
        color: "#8CA74B", 
      },
      "@media (max-width: 768px)": {
        margin: "5px",
        fontSize: "13px",
      },
      "@media (max-width: 486px)": {
        fontSize: "12px", 
        textAlign: "center",
      },
    },
    "& .imgLogo": {
      width: "80px",
      height: "41px",
      marginLeft: "25px",
      "@media (max-width: 486px)": {
        marginLeft: "10px",
      },
    },
    "& .actionBtn": {
      display: "flex",
      alignItems: "center",
      gap: "15px",
      marginRight: "20px",
      "@media (max-width: 768px)": {
        marginTop: "10px",
        gap: "10px",
        justifyContent: "center",
        width: "100%",
      },
      "@media (max-width: 486px)": {
        flexDirection: "column", 
        gap: "8px",
      },
    },
    "& .registerLink": {
      color: "#8CA74B",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "22px",
      fontFamily: "Poppins",
      textDecoration: "none",
      "@media (max-width: 486px)": {
        fontSize: "12px",
      },
    },
    "& .loginLink": {
      backgroundColor: "#8CA74B",
      color: "white",
      height: "44px",
      width: "141px",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textDecoration: "none",
      fontWeight: 700,
      textTransform: "none",
      fontFamily: "Poppins",
      fontSize: "16px",
      lineHeight: "24px",
      "@media (max-width: 486px)": {
        width: "120px", 
        height: "40px", 
        fontSize: "14px",
      },
    },
  });

export default Header;
