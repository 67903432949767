import React from "react";

import {
    Box,
    Button,
    Typography,
    IconButton,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Radio,
    // Customizable Area Start
    RadioProps,
    Modal,
    Divider,
    DialogTitle,
    Breadcrumbs,Dialog,DialogContent,DialogActions
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled, withStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { ErrorMessage, Formik } from "formik";
import { BackArrowBlack, deleteIcon, failPayment, profile, trashIcon,dollarSign, visaLogo, wallet,bankAccountWhiteLogo,bankAccountGreyLogo ,creditCardWhiteLogo,creditCardGreyLogo} from "./assets";
import Footer from "../../navigationmenu/src/Footer.web";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import CloseIcon from '@mui/icons-material/Close';
import CreditCardPayment from "../../../components/src/CreditCardPayment";
import InputLabel from '@mui/material/InputLabel';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import HeaderDropdown from "../../navigationmenu/src/HeaderDropdown.web";
import { masterCard } from "../../subscriptionbilling/src/assets";

// Customizable Area End

import PaymentController, {
    Props,
} from "./PaymentController.web";

// Customizable Area Start
const GreenRadio = withStyles({
    root: {
        color: '78716C',
        '&$checked': {
            color: '#557C29',
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

const cities = [
    { value: "NYC", label: "New York City" },
    { value: "LA", label: "Los Angeles" },
    { value: "Chicago", label: "Chicago" },
    { value: "Houston", label: "Houston" },
    { value: "Phoenix", label: "Phoenix" },
    { value: "Philadelphia", label: "Philadelphia" },
    { value: "San Antonio", label: "San Antonio" },
    { value: "San Diego", label: "San Diego" },
    { value: "Dallas", label: "Dallas" },
    { value: "San Jose", label: "San Jose" },
    { value: "Austin", label: "Austin" },
    { value: "Jacksonville", label: "Jacksonville" },
    { value: "Fort Worth", label: "Fort Worth" },
    { value: "Columbus", label: "Columbus" },
    { value: "San Francisco", label: "San Francisco" },
    { value: "Charlotte", label: "Charlotte" },
    { value: "Indianapolis", label: "Indianapolis" },
    { value: "Seattle", label: "Seattle" },
    { value: "Denver", label: "Denver" },
    { value: "Washington", label: "Washington" },
    { value: "Boston", label: "Boston" },
    { value: "El Paso", label: "El Paso" },
    { value: "Nashville", label: "Nashville" },
    { value: "Detroit", label: "Detroit" },
    { value: "Oklahoma City", label: "Oklahoma City" },
    { value: "Portland", label: "Portland" },
    { value: "Las Vegas", label: "Las Vegas" },
    { value: "Memphis", label: "Memphis" },
    { value: "Louisville", label: "Louisville" },
    { value: "Baltimore", label: "Baltimore" },
    { value: "Milwaukee", label: "Milwaukee" },
    { value: "Albuquerque", label: "Albuquerque" },
    { value: "Tucson", label: "Tucson" },
    { value: "Fresno", label: "Fresno" },
    { value: "Sacramento", label: "Sacramento" },
    { value: "Mesa", label: "Mesa" },
    { value: "Atlanta", label: "Atlanta" },
    { value: "Kansas City", label: "Kansas City" },
    { value: "Colorado Springs", label: "Colorado Springs" },
    { value: "Miami", label: "Miami" },
    { value: "Raleigh", label: "Raleigh" },
    { value: "Omaha", label: "Omaha" },
    { value: "Long Beach", label: "Long Beach" },
    { value: "Virginia Beach", label: "Virginia Beach" },
    { value: "Oakland", label: "Oakland" },
    { value: "Minneapolis", label: "Minneapolis" },
    { value: "Tampa", label: "Tampa" },
    { value: "Tulsa", label: "Tulsa" },
    { value: "Arlington", label: "Arlington" },
    { value: "New Orleans", label: "New Orleans" }
];

const states = [
    { value: "AL", label: "Alabama" },
    { value: "AK", label: "Alaska" },
    { value: "AZ", label: "Arizona" },
    { value: "AR", label: "Arkansas" },
    { value: "CA", label: "California" },
    { value: "CO", label: "Colorado" },
    { value: "CT", label: "Connecticut" },
    { value: "DE", label: "Delaware" },
    { value: "FL", label: "Florida" },
    { value: "GA", label: "Georgia" },
    { value: "HI", label: "Hawaii" },
    { value: "ID", label: "Idaho" },
    { value: "IL", label: "Illinois" },
    { value: "IN", label: "Indiana" },
    { value: "IA", label: "Iowa" },
    { value: "KS", label: "Kansas" },
    { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" },
    { value: "ME", label: "Maine" },
    { value: "MD", label: "Maryland" },
    { value: "MA", label: "Massachusetts" },
    { value: "MI", label: "Michigan" },
    { value: "MN", label: "Minnesota" },
    { value: "MS", label: "Mississippi" },
    { value: "MO", label: "Missouri" },
    { value: "MT", label: "Montana" },
    { value: "NE", label: "Nebraska" },
    { value: "NV", label: "Nevada" },
    { value: "NH", label: "New Hampshire" },
    { value: "NJ", label: "New Jersey" },
    { value: "NM", label: "New Mexico" },
    { value: "NY", label: "New York" },
    { value: "NC", label: "North Carolina" },
    { value: "ND", label: "North Dakota" },
    { value: "OH", label: "Ohio" },
    { value: "OK", label: "Oklahoma" },
    { value: "OR", label: "Oregon" },
    { value: "PA", label: "Pennsylvania" },
    { value: "RI", label: "Rhode Island" },
    { value: "SC", label: "South Carolina" },
    { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" },
    { value: "TX", label: "Texas" },
    { value: "UT", label: "Utah" },
    { value: "VT", label: "Vermont" },
    { value: "VA", label: "Virginia" },
    { value: "WA", label: "Washington" },
    { value: "WV", label: "West Virginia" },
    { value: "WI", label: "Wisconsin" },
    { value: "WY", label: "Wyoming" }
];
const bankNames = [
    "Bank of America",
    "Chase",
    "Wells Fargo",
    "Citi",
    "Capital One",
    "PNC Bank",
    "US Bank",
    "TD Bank",
    "BB&T",
    "SunTrust",
    "Fifth Third Bank",
    "HSBC",
    "Charles Schwab",
    "Ally Bank",
    "KeyBank",
    "Regions Bank",
    "Santander Bank",
    "BMO Harris Bank",
    "Huntington Bank",
    "M&T Bank",
    "Citizens Bank",
    "American Express",
    "Navy Federal Credit Union",
    "USAA",
    "Synchrony Bank",
    "Comerica",
    "Zions Bank",
    "First Republic Bank",
    "Silicon Valley Bank",
    "Discover Bank",
    "Goldman Sachs Bank USA",
    "Morgan Stanley Bank",
    "TD Ameritrade",
    "E*TRADE Bank",
    "Barclays Bank",
    "BancorpSouth",
    "BOK Financial",
    "Frost Bank",
    "Bank of the West",
    "BMO Financial Group",
    "Webster Bank",
    "Old National Bank",
    "UMB Financial Corporation",
    "People's United Financial",
    "FNB Corporation",
    "Pinnacle Financial Partners",
    "First National Bank",
    "Valley National Bank",
    "City National Bank",
    "East West Bank",
    "Cullen/Frost Bankers",
    "Synovus Bank",
    "Bank OZK",
    "Prosperity Bank",
    "Texas Capital Bank",
    "Wintrust Financial",
    "South State Bank",
    "Independent Bank",
    "Renasant Bank",
    "BankUnited",
    "First Citizens Bank",
    "United Community Bank",
    "Cadence Bank",
    "Western Alliance Bank",
    "Banner Bank",
    "Columbia Bank",
    "Great Western Bank",
    "Pacific Premier Bank",
    "First Horizon Bank",
    "Central Bank",
    "Southwest Bank",
    "Commerce Bank",
    "TowneBank",
    "Heritage Bank",
    "Bank of Hawaii",
    "BancFirst",
    "Glacier Bank",
    "Simmons Bank",
    "UMB Bank",
    "Heartland Bank",
    "Tri Counties Bank",
    "Trustmark National Bank",
    "First Financial Bank",
    "First Interstate Bank",
    "Provident Bank",
    "City Bank",
    "Hanmi Bank",
    "Mechanics Bank",
    "NBT Bank",
    "OceanFirst Bank",
    "Old Second National Bank",
    "Park National Bank",
    "Peoples Bank",
    "Pinnacle Bank",
    "PlainsCapital Bank",
    "PNC Bank",
    "Popular Bank",
    "Rabobank",
    "Raymond James Bank",
    "Republic Bank",
    "Rockland Trust",
    "Santander Bank",
    "Scotiabank",
    "Simmons Bank",
    "Southern Bank",
    "Sterling National Bank",
    "Synovus Bank",
    "TCF Bank",
    "TD Bank",
    "The Northern Trust Company",
    "Trustmark Bank",
    "UMB Bank",
    "Union Bank",
    "United Bank",
    "Valley National Bank",
    "Webster Bank",
    "Wells Fargo",
    "Western Alliance Bank",
    "Wintrust Bank",
    "Zions Bank"
];
// Customizable Area End
export default class PaymentSettings extends PaymentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <PaymentStyle>
                <HeaderDropdown data-test-id="headerComponent" navigation={this.props.navigation} id={""} />
                <Box style={{ padding: '30px', marginLeft: '20px', marginRight: '20px' }}>
                    <Breadcrumbs style={{ marginLeft: '60px' }} separator={<NavigateNextIcon fontSize="small" />}>
                        <Button className="breadcrumbsButton" color="inherit" data-test-id="breadcrumbsButton">
                            Settings
                        </Button>
                        <Typography className="breadcrumbsText">
                            Payments settings
                        </Typography>
                    </Breadcrumbs>
                    <Box style={{ display: 'flex', marginTop: "14px" }}>
                        <Button className="backButton" data-test-id="backButton"  onClick={this.handleSubscription}>
                            <img style={{ color: 'black' }} src={BackArrowBlack} alt="Back" />
                        </Button>
                        <Typography className="titleText">Payments settings</Typography>
                    </Box>
                    <Typography className="titleSubText">Update your payment preferences here.</Typography>
                    <Box className="mainPaymentDiv">
                        <Box className="paymentMethodDiv">
                            <Typography className="paymentMethodTitle">Payment method</Typography>
                            <Typography className="paymentMethodDesc">You can opt for either ACH (Automated Clearing House) or credit card as your payment method.</Typography>
                        </Box>
                        
                        <Box style={{ display: 'flex', }}>
                            
                            <Box style={{ marginTop: '32px', width: '100%', }}>
                                {this.state.subscriptionDetails.map((subscription, index) => {
                                    const paymentMethods: any = [];

                                    if (subscription && subscription.card_deatis && subscription.card_deatis.length) {
                                        subscription.card_deatis.forEach((card, cardIndex) => {
                                            paymentMethods.push({
                                                type: 'card',
                                                brand: card.brand || '',
                                                last4: card.last4 || '',
                                                identifier: `Card${index}-${cardIndex}`,
                                            });
                                        });
                                    }

                                    if (subscription && subscription.bank_details && subscription.bank_details.length) {
                                        subscription.bank_details.forEach((bank, bankIndex) => {
                                            paymentMethods.push({
                                                type: 'bank',
                                                brand: bank.bank_name || '',
                                                last4: bank.last4 || '',
                                                identifier: `ACH${index}-${bankIndex}`,
                                            });
                                        });
                                    }

                                    return (
                                        <div key={index} data-test-id="divMain" style={{width: '100%'}}>
                                            {paymentMethods.map((method: any) => (
                                            <Box key={index} className={this.state.selectedPaymentMethod === method.identifier ? "paymentBox1" : "paymentBox2"}>
                                                <Box style={{ display: 'flex', alignItems: 'center', width: '50%' }}>
                                                    <GreenRadio
                                                        checked={this.state.selectedPaymentMethod === method.identifier}
                                                        onChange={this.handlePaymentMethodChange}
                                                        value={method.identifier}
                                                        data-test-id="radioBtn"
                                                    />
                                                    <Box style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                                                        <Typography className={`visaCardText ${method.type === 'card' && method.brand.toLowerCase() === 'visa' ? 'red' : 'blue'}`}>
                                                            {method.type === 'card' ? `${method.brand.charAt(0).toUpperCase() + method.brand.slice(1)} ending in **** ${method.last4}` : `ACH ending in **** ${method.last4}`}
                                                        </Typography>
                                                        <Box style={{ display: 'flex', alignItems: 'center'}}>
                                                            <Typography className="setDefaultText">Set as default</Typography>
                                                            <Typography className="line">|</Typography>
                                                            <Typography className="setDefaultBtn" data-test-id="setDefaultBtn" onClick={() => this.handleEditModal()}>Edit</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box style={{width: '100px', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                    {method.type === 'card' ? (
                                                        method.brand ? (
                                                            <Box style={{ display: 'flex', alignItems: 'center', }}>
                                                                <img src={method.brand == "mastercard" ? masterCard : visaLogo} alt="Visa" style={{height: '42px', width: '42px', }} />
                                                            </Box>
                                                        ) : null
                                                    ) : (
                                                        <Box style={{ display: 'flex', alignItems: 'center' , }}>
                                                            <img src={wallet} alt="ACH"/>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Box>
                                            ))}
                                            {this.state.methodModal && (
                                                <Modal
                                                    data-test-id="methodModal"
                                                    open={this.state.methodModal}
                                                    onClose={() => this.setState({ methodModal: false })}
                                                >
                                                    <Box style={{
                                                        left: '50%',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        width: '100%',
                                                        backgroundColor: '#fff',
                                                        boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
                                                        padding: 30,
                                                        maxWidth: 500
                                                    }}>
                                                        {this.state.selectedPaymentMethod === 'VisaCard' ? (
                                                            <Box>
                                                                <Box>
                                                                    <Box style={{ display: "flex", marginBottom: 20, justifyContent: "space-between" }}>
                                                                        <Typography style={{ fontSize: 20, fontWeight: 700, color: '#1C2110', fontFamily: 'Poppins' }}>Edit payment method</Typography>
                                                                        <IconButton aria-label="close" onClick={this.handleCardCloseModal} data-test-id="handleCloseSimulate" style={{ top: 20, position: 'absolute', color: '#666', right: 0 }}>
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    </Box>
                                                                </Box>
                                                                <Divider style={{ margin: '10px -44px', width: 'calc(100% + 90px)' }} />
                                                                <Typography style={{ fontFamily: "Poppins", fontWeight: 700, fontSize: "16px", lineHeight: "24px", color: "#1C2110" }}>Cardholder name</Typography>
                                                                <div style={{ position: 'relative', marginBottom: '20px', marginTop: 5 }}>
                                                                    <input type="text" autoComplete="off" name="fullName" placeholder="Cardholder Name" data-test-id="inputSimulate" style={{
                                                                        borderRadius: '4px',
                                                                        width: '100%',
                                                                        border: '1px solid #ced4da',
                                                                        padding: '20px 10px 16px 10px',
                                                                        outline: 'none',
                                                                        paddingRight: '40px',
                                                                        fontSize: '16px',
                                                                        color: '#A8A29E',
                                                                        fontFamily: 'Poppins',
                                                                        fontWeight: 400
                                                                    }} />
                                                                    <img alt="Profile" src={profile} style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '10px', width: '24px', height: '24px' }} />
                                                                </div>
                                                                <Typography style={{
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: 700,
                                                                    fontSize: "16px",
                                                                    lineHeight: "24px",
                                                                    color: "#1C2110"
                                                                }}>Card number</Typography>
                                                                <div>
                                                                    <input type="text" placeholder="Enter card number" data-test-id="fillCardsDetailsTest" style={{
                                                                        fontFamily: 'Poppins',
                                                                        fontSize: '16px',
                                                                        color: '#A8A29E',
                                                                        border: '1px solid #D6D3D1',
                                                                        fontWeight: 400,
                                                                        marginTop: '5px',
                                                                        borderRadius: '4px',
                                                                        marginBottom: '20px',
                                                                        padding: '20px 10px 16px 10px',
                                                                        outline: 'none',
                                                                        width: '100%'
                                                                    }} />
                                                                </div>
                                                                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                    <div style={{ flex: '1', marginRight: '10px' }}>
                                                                        <Typography style={{
                                                                            fontFamily: "Poppins",
                                                                            lineHeight: "24px",
                                                                            fontWeight: 700,
                                                                            fontSize: "16px",
                                                                            color: "#1C2110"
                                                                        }}>Expiration date</Typography>
                                                                        <div style={{
                                                                            marginBottom: '20px',
                                                                            marginTop: '5px',
                                                                            borderRadius: '4px',
                                                                            border: '1px solid #D6D3D1',
                                                                            padding: '8px 4px 8px 10px',
                                                                        }}>
                                                                            <input type="text" placeholder="MM/YY" style={{
                                                                                color: '#A8A29E',
                                                                                fontSize: '16px',
                                                                                width: '100%',
                                                                                fontFamily: 'Poppins',
                                                                                border: 'none',
                                                                                fontWeight: 400,
                                                                                outline: 'none',
                                                                                backgroundColor: 'transparent',
                                                                                padding: '10px'
                                                                            }} />
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginLeft: '10px', flex: '1' }}>
                                                                        <Typography style={{
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: 700,
                                                                            lineHeight: "24px",
                                                                            color: "#1C2110",
                                                                            fontSize: "16px"
                                                                        }}>Security code</Typography>
                                                                        <div style={{
                                                                            border: '1px solid #D6D3D1',
                                                                            marginBottom: '20px',
                                                                            borderRadius: '4px',
                                                                            padding: '8px 4px 8px 10px',
                                                                            marginTop: '5px'
                                                                        }}>
                                                                            <input type="text" placeholder="CVC" style={{
                                                                                fontFamily: 'Poppins',
                                                                                color: '#A8A29E',
                                                                                fontSize: '16px',
                                                                                width: '100%',
                                                                                fontWeight: 400,
                                                                                outline: 'none',
                                                                                border: 'none',
                                                                                backgroundColor: 'transparent',
                                                                                padding: '10px'
                                                                            }} />
                                                                        </div>
                                                                    </div>
                                                                </Box>
                                                            </Box>
                                                        ) : (
                                                            <Box>
                                                                <Box>
                                                                    <Box style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
                                                                        <Typography style={{ fontWeight: 700, fontSize: 20, color: '#1C2110', fontFamily: 'Poppins' }}>Edit payment method</Typography>
                                                                        <IconButton
                                                                            aria-label="close"
                                                                            onClick={this.handleCardCloseModal}
                                                                            data-tes-id="handleCloseSimulate"
                                                                            style={{
                                                                                position: 'absolute',
                                                                                right: 0,
                                                                                top: 20,
                                                                                color: '#666',
                                                                            }}>
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    </Box>
                                                                </Box>
                                                                <Divider style={{
                                                                    width: 'calc(100% + 90px)',
                                                                    margin: '10px -44px',
                                                                }} />
                                                                <Typography style={{
                                                                    fontFamily: "Poppins",
                                                                    fontSize: "16px",
                                                                    fontWeight: 700,
                                                                    color: "#1C2110",
                                                                    lineHeight: "24px",
                                                                }}>Full name</Typography>
                                                                <div style={{ position: 'relative', marginBottom: '20px', marginTop: 5 }}>
                                                                    <input
                                                                        type="text"
                                                                        style={{
                                                                            width: '100%',
                                                                            padding: '20px 10px 16px 10px',
                                                                            border: '1px solid #ced4da',
                                                                            paddingRight: '40px',
                                                                            outline: 'none',
                                                                            borderRadius: '4px',
                                                                            fontWeight: 400,
                                                                            fontSize: '16px',
                                                                            fontFamily: 'Poppins',
                                                                            color: '#A8A29E',
                                                                        }}
                                                                        autoComplete="off"
                                                                        name="fullName"
                                                                        placeholder="Enter Your Full Name"
                                                                        data-test-id="inputSimulate" />
                                                                </div>
                                                                <Typography style={{
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: 700,
                                                                    fontSize: "16px",
                                                                    lineHeight: "24px",
                                                                    color: "#1C2110"
                                                                }}>Bank name</Typography>
                                                                <div>
                                                                    <input type="text" placeholder="Enter bank name" data-test-id="fillCardsDetailsTest" style={{
                                                                        fontSize: '16px',
                                                                        fontWeight: 400,
                                                                        color: '#A8A29E',
                                                                        fontFamily: 'Poppins',
                                                                        borderRadius: '4px',
                                                                        padding: '20px 10px 16px 10px',
                                                                        marginBottom: '20px',
                                                                        border: '1px solid #D6D3D1',
                                                                        marginTop: '5px',
                                                                        width: '100%',
                                                                        outline: 'none',
                                                                    }} />
                                                                </div>
                                                                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                    <div style={{ flex: '1', marginRight: '10px' }}>
                                                                        <Typography style={{
                                                                            fontFamily: "Poppins",
                                                                            fontSize: "16px",
                                                                            lineHeight: "24px",
                                                                            fontWeight: 700,
                                                                            color: "#1C2110"
                                                                        }}>Acount number</Typography>
                                                                        <div style={{
                                                                            marginBottom: '20px',
                                                                            border: '1px solid #D6D3D1',
                                                                            borderRadius: '4px',
                                                                            marginTop: '5px',
                                                                            padding: '8px 4px 8px 10px',
                                                                        }}>
                                                                            <input type="text" placeholder="MM/YY" style={{
                                                                                color: '#A8A29E',
                                                                                fontSize: '16px',
                                                                                fontFamily: 'Poppins',
                                                                                fontWeight: 400,
                                                                                border: 'none',
                                                                                width: '100%',
                                                                                outline: 'none',
                                                                                padding: '10px',
                                                                                backgroundColor: 'transparent'
                                                                            }} />
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ flex: '1', marginLeft: '10px' }}>
                                                                        <Typography style={{
                                                                            fontWeight: 700,
                                                                            fontSize: "16px",
                                                                            fontFamily: "Poppins",
                                                                            lineHeight: "24px",
                                                                            color: "#1C2110"
                                                                        }}>Bank account type</Typography>
                                                                        <div style={{
                                                                            marginBottom: '20px',
                                                                            marginTop: '5px',
                                                                            border: '1px solid #D6D3D1',
                                                                            borderRadius: '4px',
                                                                            padding: '8px 4px 8px 10px',
                                                                        }}>
                                                                            <input type="text" placeholder="Bank type" style={{
                                                                                fontSize: '16px',
                                                                                color: '#A8A29E',
                                                                                fontFamily: 'Poppins',
                                                                                fontWeight: 400,
                                                                                width: '100%',
                                                                                border: 'none',
                                                                                outline: 'none',
                                                                                padding: '10px',
                                                                                backgroundColor: 'transparent'
                                                                            }} />
                                                                        </div>
                                                                    </div>
                                                                </Box>
                                                                <Typography style={{
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: 700,
                                                                    fontSize: "16px",
                                                                    lineHeight: "24px",
                                                                    color: "#1C2110"
                                                                }}>Routing number</Typography>
                                                                <div>
                                                                    <input type="text" data-test-id="fillCardsDetailsTest" placeholder="Enter routing number" style={{
                                                                        fontSize: '16px',
                                                                        fontFamily: 'Poppins',
                                                                        color: '#A8A29E',
                                                                        border: '1px solid #D6D3D1',
                                                                        fontWeight: 400,
                                                                        padding: '20px 10px 16px 10px',
                                                                        borderRadius: '4px',
                                                                        marginTop: '5px',
                                                                        marginBottom: '20px',
                                                                        outline: 'none',
                                                                        width: '100%',
                                                                    }} />
                                                                </div>
                                                            </Box>
                                                        )}
                                                        <Box style={{ display: 'flex' }}>
                                                            <img src={deleteIcon} style={{ width: 18, height: 20 }} />
                                                            <Typography
                                                                style={{ fontSize: 14, fontFamily: 'Poppins', fontWeight: 700, color: '#DC2626', cursor: 'pointer' }}
                                                                onClick={this.handleDeletePaymentMethodModal}
                                                                data-test-id="deletePayment" >
                                                                Delete payment method
                                                            </Typography>
                                                        </Box>
                                                        <Divider style={{
                                                            width: 'calc(100% + 90px)',
                                                            margin: '10px -44px',
                                                            marginTop: 20
                                                        }} />
                                                        <Box style={{ display: 'flex', justifyContent: 'center', paddingBottom: 30, gap: 30 }}>
                                                            <Button style={{
                                                                width: '220px',
                                                                border: '1px solid #C2E176',
                                                                backgroundColor: '#F8FFE7',
                                                                height: '56px',
                                                                marginTop: '32px',
                                                                borderRadius: '8px'
                                                            }} onClick={this.handleDeleteCloseModal}>
                                                                <Typography style={{
                                                                    fontFamily: "Poppins",
                                                                    fontSize: "16px",
                                                                    lineHeight: "24px",
                                                                    fontWeight: 700,
                                                                    color: "#8CA74B",
                                                                    textTransform: 'none'
                                                                }}>Cancel</Typography>
                                                            </Button>
                                                            <Button style={{
                                                                borderRadius: '8px',
                                                                backgroundColor: "#8CA74B",
                                                                height: '56px',
                                                                width: '195px',
                                                                marginTop: '32px',
                                                            }}>
                                                                <Typography style={{
                                                                    fontFamily: "Poppins",
                                                                    lineHeight: "24px",
                                                                    fontSize: "16px",
                                                                    fontWeight: 700,
                                                                    color: "#FFFFFF",
                                                                    textTransform: 'none'
                                                                }}>Save</Typography>
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </Modal>
                                            )}
                                            <div>
                                                <Modal
                                                    open={this.state.sureDeleteModal}
                                                    onClose={this.handleDeleteCloseModal}>
                                                    <Box style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        maxWidth: 500,
                                                        transform: 'translate(-50%, -50%)',
                                                        width: '100%',
                                                        left: '50%',
                                                        backgroundColor: '#fff',
                                                        boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
                                                        padding: 30,
                                                    }} >
                                                        <Box style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
                                                            <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Delete payment method</Typography>
                                                            <IconButton
                                                                aria-label="close"
                                                                onClick={this.handleSureDeleteModal}
                                                                data-test-id="handlesuredelete"
                                                                style={{
                                                                    top: 20,
                                                                    position: 'absolute',
                                                                    right: 0,
                                                                    color: '#666',
                                                                }} >
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </Box>
                                                        <Divider style={{
                                                            width: 'calc(100% + 90px)',
                                                            margin: '10px -44px',
                                                        }} />
                                                        <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: 30 }}>
                                                            <div style={{
                                                                height: "130px",
                                                                width: "130px",
                                                                backgroundColor: "#8CA74B",
                                                                borderRadius: "50%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignSelf: 'center',
                                                                alignItems: "center"
                                                            }}>
                                                                <img src={trashIcon} className="image" alt="Centered" style={{ width: 85 }} />
                                                            </div>
                                                        </Box>
                                                        <Typography style={{ fontFamily: 'Poppins', fontWeight: 700, fontSize: 20, color: '#1C2110', marginTop: 20, display: 'flex', textAlign: 'center' }}>
                                                            Are you sure you want to delete your payment method?
                                                        </Typography>
                                                        <Typography style={{ fontFamily: 'Poppins', fontWeight: 400, fontSize: 14, marginTop: 20, color: '#1C2110', display: 'flex', textAlign: 'center' }}>
                                                            If you proceed with deleting your payment method, you will have the option to add a new one afterwards.
                                                        </Typography>
                                                        <Divider style={{
                                                            marginTop: 20,
                                                            margin: '10px -44px',
                                                            width: 'calc(100% + 90px)'
                                                        }} />
                                                        <Box style={{ display: 'flex', justifyContent: 'center', gap: 30, paddingBottom: 20 }}>
                                                            <Button style={{
                                                                backgroundColor: "#C2E176",
                                                                width: '195px',
                                                                borderRadius: '8px',
                                                                marginTop: '20px',
                                                                height: '56px'
                                                            }} onClick={this.handleDeleteCloseModal}>
                                                                <Typography style={{
                                                                    fontSize: "16px",
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: 700,
                                                                    lineHeight: "24px",
                                                                    textTransform: 'none',
                                                                    color: "#8CA74B"
                                                                }}>Keep it</Typography>
                                                            </Button>
                                                            <Button style={{
                                                                borderRadius: '8px',
                                                                height: '56px',
                                                                backgroundColor: "#8CA74B",
                                                                width: '195px',
                                                                marginTop: '20px'
                                                            }}>
                                                                <Typography style={{
                                                                    fontFamily: "Poppins",
                                                                    fontSize: "16px",
                                                                    fontWeight: 700,
                                                                    lineHeight: "24px",
                                                                    color: "#FFFFFF",
                                                                    textTransform: 'none'
                                                                }}
                                                                    onClick={() => {
                                                                        const paymentMethod = subscription.payment_method;
                                                                        const isCard = paymentMethod && paymentMethod.type === 'card';
                                                                        if (isCard) {
                                                                            this.getCardPaymentMethod();
                                                                        } else {
                                                                            this.getBankPaymentMethod();
                                                                        }
                                                                    }}>Yes, delete it</Typography>
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </Modal>
                                                <div>
                                                    <Modal
                                                        open={this.state.successFullModal}
                                                        onClose={this.handleSuccessModal}
                                                        data-test-id="handlesuccess" >
                                                        <Box style={{
                                                            position: 'absolute',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            top: '50%',
                                                            maxWidth: 500,
                                                            width: '100%',
                                                            backgroundColor: '#fff',
                                                            padding: 30,
                                                            boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
                                                            borderBottomRightRadius: 50
                                                        }}>
                                                            <Box>
                                                                <Box style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
                                                                    <Typography style={{ fontSize: 20, fontWeight: 700, color: '#1C2110', fontFamily: 'Poppins' }}>Delete payment method</Typography>
                                                                    <IconButton aria-label="close" onClick={this.handleSuccessCloseModal} data-test-id="handleCloseSimulate6" style={{ right: 0, top: 20, color: '#666', position: 'absolute' }}>
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </Box>
                                                            </Box>
                                                            <Divider style={{
                                                                margin: '10px -44px',
                                                                width: 'calc(100% + 90px)'
                                                            }} />
                                                            <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: 30 }}>
                                                                <div style={{
                                                                    width: "130px",
                                                                    height: "130px",
                                                                    backgroundColor: "#34D399",
                                                                    borderRadius: "50%",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignSelf: 'center',
                                                                    alignItems: "center"
                                                                }}>
                                                                    <img src={trashIcon} className="image" alt="Centered" style={{ width: 85 }} />
                                                                </div>
                                                            </Box>
                                                            <Box style={{ display: 'flex', justifyContent: 'center', maxWidth: '490', width: '100%' }}>
                                                                <Typography style={{ fontFamily: 'Poppins', fontWeight: 700, color: '#1C2110', fontSize: 20, display: 'flex', textAlign: 'center' }}>The payment method has been successfully deleted</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Modal>
                                                </div>
                                                <div>
                                                    <Modal
                                                        open={this.state.deleteSuccessfulModal}
                                                        onClose={this.handleDeleteSuccessCloseModal}
                                                        data-test-id="deletesucessclose">
                                                        <Box style={{
                                                            top: '50%',
                                                            position: 'absolute',
                                                            maxWidth: 500,
                                                            left: '50%',
                                                            width: '100%',
                                                            transform: 'translate(-50%, -50%)',
                                                            padding: 30,
                                                            backgroundColor: '#fff',
                                                            borderBottomRightRadius: 50,
                                                            boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)'
                                                        }}>
                                                            <Box>
                                                                <Box style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
                                                                    <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Delete payment method</Typography>
                                                                    <IconButton
                                                                        aria-label="close"
                                                                        onClick={this.handleDeleteSuccessCloseModal}
                                                                        data-tes-id="handleCloseSimulate"
                                                                        style={{
                                                                            position: 'absolute',
                                                                            right: 0,
                                                                            top: 20,
                                                                            color: '#666',
                                                                        }} >
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </Box>
                                                            </Box>
                                                            <Divider style={{
                                                                margin: '10px -44px',
                                                                width: 'calc(100% + 90px)'
                                                            }} />
                                                            <Box style={{ display: 'flex', marginTop: 30, justifyContent: 'center', flexDirection: 'column' }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    width: "130px",
                                                                    borderRadius: "50%",
                                                                    height: "130px",
                                                                    justifyContent: "center",
                                                                    backgroundColor: "#F87171",
                                                                    alignSelf: 'center',
                                                                    alignItems: "center"
                                                                }}>
                                                                    <img src={trashIcon} alt="Centered" style={{ width: 85 }} className="image" />
                                                                </div>
                                                            </Box>
                                                            <Box style={{ display: 'flex', maxWidth: '490', justifyContent: 'center', width: '100%', marginTop: 20 }}>
                                                                <Typography style={{ fontFamily: 'Poppins', fontWeight: 700, fontSize: 20, color: '#1C2110', textAlign: 'center', display: 'flex' }}>Failed to delete the payment method</Typography>
                                                            </Box>
                                                            <Divider style={{
                                                                marginTop: 20,
                                                                margin: '10px -44px',
                                                                width: 'calc(100% + 90px)'
                                                            }} />
                                                            <Box style={{ display: 'flex', paddingBottom: 20, gap: 30, justifyContent: 'center' }}>
                                                                <Button style={{
                                                                    backgroundColor: "#C2E176",
                                                                    borderRadius: '8px',
                                                                    marginTop: '20px',
                                                                    height: '56px',
                                                                    width: '195px'
                                                                }} onClick={this.handleDeleteSuccessCloseModal}>
                                                                    <Typography style={{
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: 700,
                                                                        fontSize: "16px",
                                                                        lineHeight: "24px",
                                                                        color: "#8CA74B",
                                                                        textTransform: 'none'
                                                                    }}>Cancel</Typography>
                                                                </Button>
                                                                <Button style={{
                                                                    height: '56px',
                                                                    borderRadius: '8px',
                                                                    width: '195px',
                                                                    marginTop: '20px',
                                                                    backgroundColor: "#8CA74B"
                                                                }}>
                                                                    <Typography style={{
                                                                        fontSize: "16px",
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: 700,
                                                                        color: "#FFFFFF",
                                                                        textTransform: 'none',
                                                                        lineHeight: "24px"
                                                                    }} onClick={this.handleNewPaymentMethod} data-test-id="buttonTestTryagain">Try again</Typography>
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </Modal>
                                                </div>

                                                <div>
                                                    <Modal
                                                        onClose={this.handleDeleteCloseModal}
                                                        open={this.state.deleteModal}>
                                                        <Box style={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            width: '100%',
                                                            transform: 'translate(-50%, -50%)',
                                                            maxWidth: 500,
                                                            backgroundColor: '#fff',
                                                            boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
                                                            padding: 30,
                                                        }}>
                                                            <Box style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
                                                                <Typography style={{ fontSize: 20, fontWeight: 700, color: '#1C2110', fontFamily: 'Poppins' }}>Delete payment method</Typography>
                                                                <IconButton
                                                                    aria-label="close"
                                                                    onClick={this.handleDeleteCloseModal}
                                                                    data-test-id="handleCloseSimulate4"
                                                                    style={{
                                                                        position: 'absolute',
                                                                        right: 0,
                                                                        top: 20,
                                                                        color: '#666',
                                                                    }}>
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </Box>
                                                            <Divider style={{
                                                                width: 'calc(100% + 90px)',
                                                                margin: '10px -44px',
                                                            }} />
                                                            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 30 }}>
                                                                <div style={{
                                                                    borderRadius: "50%",
                                                                    width: "130px",
                                                                    height: "130px",
                                                                    backgroundColor: "#8CA74B",
                                                                    justifyContent: "center",
                                                                    display: "flex",
                                                                    alignSelf: 'center',
                                                                    alignItems: "center"
                                                                }}>
                                                                    <img src={failPayment} className="image" alt="Centered" style={{ width: 85 }} />
                                                                </div>
                                                            </Box>
                                                            <Typography style={{ fontFamily: 'Poppins', fontWeight: 700, color: '#1C2110', fontSize: 20, display: 'flex', marginTop: 20, textAlign: 'center' }}>
                                                                You cannot delete the only payment method
                                                            </Typography>
                                                            <Typography style={{ fontFamily: 'Poppins', fontSize: 14, color: '#1C2110', fontWeight: 400, display: 'flex', marginTop: 20, textAlign: 'center' }}>
                                                                An active payment method is required. If you wish to delete it, you must add a new one to replace the current method.
                                                            </Typography>
                                                            <Divider style={{
                                                                width: 'calc(100% + 90px)',
                                                                marginTop: 20,
                                                                margin: '10px -44px'
                                                            }} />
                                                            <Box style={{ display: 'flex', justifyContent: 'center', gap: 30, paddingBottom: 20 }}>
                                                                <Button style={{
                                                                    width: '220px',
                                                                    backgroundColor: "#C2E176",
                                                                    marginTop: '20px',
                                                                    borderRadius: '8px',
                                                                    height: '56px'
                                                                }} onClick={this.handleDeleteCloseModal}>
                                                                    <Typography style={{
                                                                        fontSize: "16px",
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: 700,
                                                                        lineHeight: "24px",
                                                                        textTransform: 'none',
                                                                        color: "#8CA74B"
                                                                    }}>Cancel</Typography>
                                                                </Button>
                                                                <Button style={{
                                                                    borderRadius: '8px',
                                                                    backgroundColor: "#8CA74B",
                                                                    width: '220px',
                                                                    marginTop: '20px',
                                                                    height: '56px'
                                                                }}>
                                                                    <Typography style={{
                                                                        fontWeight: 700,
                                                                        fontFamily: "Poppins",
                                                                        lineHeight: "24px",
                                                                        fontSize: "16px",
                                                                        textTransform: 'none',
                                                                        color: "#FFFFFF"
                                                                    }} onClick={this.handleDeleteSuccessModal}>Add new payment method</Typography>
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <Button className="newPyamentBtn" data-test-id="newPyamentBtn">
                                    <AddCircleOutlineIcon style={{width: '16px', height: '16px'}} />
                                    <Typography className="newPyamentBtnText" onClick={this.handleNewPaymentMethod}>
                                        Add new payment Method
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>

                        <Box className="paymentHistoryDiv">
                            <Typography className="paymentHistoryTitle">Payments history</Typography>
                            <Typography className="paymentHistoryDesc">View a detailed record of your past transactions and billing activities. Track payments, subscriptions, and any changes to your account balance over time.</Typography>
                        </Box>
                        <Box style={{ display: 'flex', gap: '16px', marginTop: '32px', }}>
                            <Button className="downloadBtn" data-test-id="downloadBtn">Download all</Button>
                            <Button className="historyBtn" data-test-id="historyBtn" onClick={this.handlePaymentHistory}>Check my payment history</Button>
                        </Box>
                        <Box className="billingAddressBox">
                            <Typography className="billingTitle">Billing Address</Typography>
                            <Typography className="billingDescription">Designated location associated with your financial account, used for invoicing and payment purposes.</Typography>
                        </Box>
                        <Box style={{ display: 'flex', marginTop: '30px' }}>
                            
                            <Box style={{ width: '100%' }}>
                                <Formik
                                    data-test-id="formikBillingForm"
                                    enableReinitialize
                                    initialValues={{
                                        fullName: localStorage.getItem('fullName') || this.state.fullName,
                                        address: localStorage.getItem('address') || this.state.address,
                                        cityName:  localStorage.getItem('city') || this.state.cityName,
                                        stateName: localStorage.getItem('state') || this.state.stateName,
                                        zipCode:  localStorage.getItem('zipCode') || this.state.zipCode,
                                    }}
                                    validationSchema={this.paymentDetailsSchema}
                                    onSubmit={values => {
                                        this.billingAddressApi(values);
                                    }}
                                >
                                    {({ handleSubmit, handleChange, values ,setFieldValue}) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box className="formNameAndAddressBox">
                                                <Box className="formNameBox">
                                                    <Typography className="formTextTitle">Full Name</Typography>
                                                    <TextField
                                                        data-test-id="fullNameField"
                                                        type="text"
                                                        value={values.fullName}
                                                        onChange={handleChange}
                                                        className="textField"
                                                        autoComplete="off"
                                                        fullWidth
                                                        name="fullName"
                                                        placeholder="Enter Your Full Name"
                                                        variant="outlined"
                                                    />
                                                    <ErrorMessage name="fullName" component="div" className="errorMessage" />
                                                </Box>
                                                <Box className="formAddressBox">
                                                    <Typography className="formTextTitle">Address</Typography>
                                                    <TextField
                                                        data-test-id="addressField"
                                                        type="text"
                                                        value={values.address}
                                                        onChange={handleChange}
                                                        className="textField"
                                                        autoComplete="off"
                                                        fullWidth
                                                        name="address"
                                                        placeholder="Enter Your Address"
                                                        variant="outlined"
                                                    />
                                                    <ErrorMessage name="address" component="div" className="errorMessage" />
                                                </Box>
                                                
                                            </Box>
                                            <Box style={{ width: '100%' }}>
                                                    <Typography className="formTextTitle">Zip code</Typography>
                                                    <TextField
                                                        fullWidth
                                                        data-test-id="zipCodeField"
                                                        value={this.state.zipCode}
                                                        onChange={this.handleZipcodeChange}
                                                        className="textField"
                                                        autoComplete="off"
                                                        name="zipCode"
                                                        placeholder="Enter Your Zip code"
                                                        variant="outlined"
                                                    />
                                                    <ErrorMessage name="zipCode" component="div" className="errorMessage" />
                                                </Box>

                                         


                                            <Box style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                             
                                                    <FormControl variant="outlined" style={{ marginBottom: '20px',width: '100%' }} fullWidth>
                                                    <InputLabel htmlFor="cityField">State</InputLabel>
                                                    <TextField
                                                        name="state"
                                                        data-test-id="state"
                                                        className="placeholderTexts"
                                                        placeholder="Select a state"
                                                        variant="outlined"
                                                        autoComplete="off"
                                                        disabled
                                                        value={this.state.state}
                                                        onChange={(e) => {
                                                            this.handleCityChange(e);
                                                            setFieldValue('city', e.target.value);
                                                        }}
                                                    />
                                                </FormControl>
                                                    <ErrorMessage name="stateName" component="div" className="errorMessage" />
                                             
                                                <FormControl variant="outlined" style={{ marginBottom: '20px', width: '100%' }} fullWidth>
                                                    <InputLabel htmlFor="cityField">City</InputLabel>
                                                     <TextField
                                                                        name="city"
                                                                        data-test-id="city"
                                                                        className="placeholderTexts"
                                                                        placeholder="Select a city"
                                                                        variant="outlined"
                                                                        autoComplete="off"
                                                                        disabled
                                                                        value={this.state.city}
                                                                        onChange={(e) => {
                                                                            this.handleCityChange(e);
                                                                            setFieldValue('city', e.target.value);
                                                                        }} 
                                                                    />
                                                </FormControl>
                                            <ErrorMessage name="cityName" component="div" className="errorMessage" />



                                            </Box>
                                            <Box className="actionBtnsBox">
                                                <Button className="cancelBtn" type="submit" data-test-id="cancelBtn">Cancel</Button>
                                                <Button className="saveBtn" type="submit" data-test-id="saveBtn" >Save changes</Button>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Dialog
                    data-test-id="handleCloseNewPaymentMethod"
                    open={this.state.addNewPaymentModal}
                    onClose={this.handleCloseNewPaymentMethod}
                    className="dialogContainer"
                    PaperProps={{
                        style: {
                            width: '600px',
                            borderRadius: 8,
                            borderBottomRightRadius: 50,
                        },
                    }}
                >
                    <DialogMainContent>
                        <Box className="headerBox">
                            <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }} >Add a new payment method</Typography>
                            <IconButton
                                data-test-id='deleteIconDialog'
                                className="closeIcon"
                                onClick={this.handleCloseNewPaymentMethod}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Divider style={{ marginTop: '25px', marginBottom: '25px', color: '#E7E5E4', width: 'calc(100% + 90px)', margin: '20px -44px' }} />
                        <Box style={{
                            marginTop: 20,
                            backgroundColor: "#f7f0f0",
                            width: "100%",
                            padding: 2,
                            borderRadius: 40
                        }}>
                            <ToggleButtonGroup
                                value={this.state.paymentMethod}
                                exclusive
                                onChange={this.handleToggleButton}
                                aria-label="payment method"
                                fullWidth
                                data-test-id="toggleButton"

                            >
                                <ToggleButton
                                    value="bankTransfer"
                                    aria-label="bank transfer"
                                    style={{
                                        fontSize: 16,
                                        textTransform: 'none',
                                        fontFamily: 'Poppins',
                                        borderRadius: 30,
                                        borderColor: '#f7f0f0',
                                        fontWeight: 700,
                                        backgroundColor: this.state.paymentMethod === 'bankTransfer' ? '#8CA74B' : '#f7f0f0',
                                        color: this.state.paymentMethod === 'bankTransfer' ? '#FAFAF9' : '#78716C'
                                    }}
                                >
                                    <img
                                        src={this.state.paymentMethod === 'bankTransfer' ? bankAccountWhiteLogo : bankAccountGreyLogo}
                                        alt="Bank Transfer"
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            marginRight: '8px',
                                            backgroundColor: this.state.paymentMethod === 'bankTransfer' ? '#8CA74B' : '#f7f0f0',
                                        }}
                                    />
                                    Bank transfer (ACH)
                                </ToggleButton>
                                <ToggleButton value="creditCard" aria-label="credit card" style={{
                                    fontSize: 16,
                                    fontWeight: 700,
                                    fontFamily: 'Poppins',
                                    borderColor: '#f7f0f0',
                                    textTransform: 'none',
                                    borderRadius: 30,
                                    backgroundColor: this.state.paymentMethod === 'creditCard' ? '#8CA74B' : '#f7f0f0',
                                    color: this.state.paymentMethod === 'creditCard' ? '#ffffff' : '#78716C'
                                }}>
                                    <img
                                        src={this.state.paymentMethod === 'creditCard' ? creditCardWhiteLogo : creditCardGreyLogo}
                                        alt="Credit Card"
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            marginRight: '8px',
                                            backgroundColor: this.state.paymentMethod === 'creditCard' ? '#8CA74B' : '#f7f0f0',
                                        }}
                                    />
                                    Credit Card
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>

                        {this.state.paymentMethod === 'creditCard' && (
                            <CreditCardPayment handleCloseModal={this.handleCloseModal}
                                paymentCallback={(id: any) => { this.createSubscriptionApiCall(id) }}
                                buttonText="Save payment method"
                                creditCardText="Fill in this form with your bank account details"
                            />
                        )}

                        {this.state.paymentMethod === 'bankTransfer' && (
                            <Formik
                                data-test-id="formikProfileForm"
                                enableReinitialize
                                initialValues={{
                                    accountHolderName: this.state.accountHolderName,
                                    routingNumber: this.state.routingNumber,
                                    accountType: this.state.accountType,
                                    accountNumber: this.state.accountNumber,
                                    bankName: this.state.bankName,
                                }}
                                validationSchema={this.upiDetialsSchema}
                                onSubmit={async (values, { setSubmitting }) => {
                                    this.createSubscriptionAchApiCall(values);
                                    setSubmitting(false);
                                }} >

                                {({ handleSubmit, handleChange, values, setFieldValue }) => (
                                    <form onSubmit={handleSubmit} style={{ marginTop: 30 }} >
                                        <div style={{ marginTop: 30 }}>
                                            <Typography className="accountDetailsText">Fill in this form with your bank account details.</Typography>
                                            <Typography className="titleTexts">Full Name</Typography>
                                            <TextField
                                                value={values.accountHolderName}
                                                onChange={handleChange}
                                                data-test-id="fullNameInput"
                                                type="text"
                                                style={{ marginBottom: '20px', marginTop: 5 }}
                                                autoComplete="off"
                                                fullWidth
                                                name="accountHolderName"
                                                placeholder="Your Name"
                                                variant="outlined"
                                            />
                                            <ErrorMessage name="accountHolderName" component="div" className="errorMessage" />
                                            <div >
                                                <Typography className="titleTexts" style={{ marginBottom: '-8px' }}>Bank name</Typography>
                                                <FormControl variant="outlined" fullWidth margin="normal">
                                                    <Select
                                                        style={{ marginBottom: '10px', color: '#A8A29E' }}
                                                        value={values.bankName}
                                                        data-test-id="bankfield"
                                                        onChange={(event) => setFieldValue('bankName', event.target.value)}
                                                        displayEmpty
                                                        name="bankName"
                                                        variant="outlined"
                                                        autoComplete="off"
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Select bank name
                                                        </MenuItem>
                                                        {bankNames.map((bank, index) => (
                                                            <MenuItem key={index} value={bank}>
                                                                {bank}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <ErrorMessage name="bankName" component="div" className="errorMessage" />
                                            </div>
                                            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '5px' }}>
                                                <div style={{ flex: '1', marginRight: '10px' }}>
                                                    <Typography className="titleTexts">Account number</Typography>
                                                    <TextField
                                                        value={values.accountNumber}
                                                        onChange={handleChange}
                                                        data-test-id="accountNumberField"
                                                        type="text"
                                                        style={{ marginBottom: '20px', marginTop: 5 }}
                                                        autoComplete="off"
                                                        fullWidth
                                                        name="accountNumber"
                                                        placeholder="Your account number"
                                                        variant="outlined"
                                                    />
                                                    <ErrorMessage name="accountNumber" component="div" className="errorMessage" />
                                                </div>
                                                <div style={{ flex: '1', marginLeft: '10px' }}>
                                                    <Typography className="titleTexts">Bank account type</Typography>
                                                    <FormControl style={{ marginBottom: '20px', marginTop: 5 }} fullWidth>
                                                        <Select
                                                            style={{ color: '#A8A29E' }}
                                                            value={values.accountType}
                                                            data-test-id="bankAccTypeField"
                                                            onChange={(event) => setFieldValue('accountType', event.target.value)}
                                                            name="accountType"
                                                            variant="outlined"
                                                            autoComplete="off"
                                                            fullWidth
                                                            displayEmpty
                                                        >
                                                            <MenuItem value="" disabled>Select bank account type </MenuItem>
                                                            <MenuItem value="individual" style={{ color: '#1C1917' }}>Individual</MenuItem>
                                                            <MenuItem value="company" style={{ color: '#1C1917' }}>Company</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <ErrorMessage name="accountType" component="div" className="errorMessage" />
                                                </div>
                                            </Box>
                                            <Typography className="titleTexts" style={{ marginTop: '5px' }}>Routing number</Typography>
                                            <TextField
                                                value={values.routingNumber}
                                                onChange={handleChange}
                                                data-test-id="routingNumberField"
                                                type="text"
                                                style={{ marginBottom: '20px', marginTop: 5 }}
                                                autoComplete="off"
                                                fullWidth
                                                name="routingNumber"
                                                placeholder="Your routing number"
                                                variant="outlined"
                                            />
                                            <ErrorMessage name="routingNumber" component="div" className="errorMessage" />
                                        </div>
                                        <Divider style={{ marginTop: '25px', marginBottom: '40px', color: '#E7E5E4', width: 'calc(100% + 95px)', margin: '20px -44px' }} />
                                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Button className="cancelBtn" onClick={this.handleCloseNewPaymentMethod} data-test-id="cancelButton">Cancel</Button>
                                            <Button className="saveBtn" type="submit" data-test-id="continueButton"  >Save payment method</Button>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        )}
                    </DialogMainContent>
                </Dialog>
                <Dialog
                    className="dialogContainer"
                    open={this.state.openSuccessModal}
                    onClose={this.handleCloseModal}
                    PaperProps={{
                        style: {
                            position: 'relative',
                            height: 'auto',
                            width: '510px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            borderRadius: 8,
                            borderBottomRightRadius: 50,
                        },
                    }}
                >
                    <DialogTitle>
                        <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Add a new payment method</Typography>
                        <IconButton
                            data-test-id='deleteIcon'
                            onClick={this.handleCloseModal}
                            style={{
                                right: 8,
                                position: 'absolute',
                                color: '#666',
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogContent style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: '40px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Box style={{
                                backgroundColor: '#34D399',
                                textAlign: 'center',
                                height: '130px',
                                borderRadius: '80px',
                                display: 'flex',
                                width: '130px',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <img src={dollarSign} style={{ color: 'white', height: '65px', width: '65px' }} />
                            </Box>
                        </div>
                        <Box style={{ textAlign: 'center', marginBottom: '20px', marginLeft: '10px', marginRight: '10px', marginTop: '40px' }}>
                            <Typography style={{
                                fontFamily: "Poppins",
                                fontWeight: 700,
                                fontSize: "20px",
                                lineHeight: "28px",
                                color: "#1C2110",
                                alignItems: 'center'
                            }} >Payment Method added</Typography>
                            <Typography style={{
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#1C2110",
                                alignItems: 'center',
                                marginTop: '10px'
                            }}>Your payment method  {this.state.addedPaymentMethod === 'bankTransfer' ? 'bank transfer (ACH)' : 'credit card'} has been added successfully. </Typography>
                        </Box>
                    </DialogContent>
                </Dialog>

                <Dialog open={this.state.isPaymentModelOpen} data-test-id="dilogcloseButton" PaperProps={{
                    style: {
                        height: 555,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderRadius: 8,
                        borderBottomRightRadius: 50,
                    },
                }} >
                    <DialogTitle style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins' }}>
                        <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Add a new payment method</Typography>
                        <IconButton onClick={this.handlCloseFailPaymentMethod} aria-label="close" style={{ position: 'absolute', right: 8, top: 8, color: '#666' }}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogContent style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Box style={{
                                borderRadius: '80px',
                                textAlign: 'center',
                                backgroundColor: '#F87171',
                                width: '130px',
                                height: '130px',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <img src={dollarSign} style={{ color: 'white', height: '65px', width: '65px' }} />
                            </Box>
                        </div>
                        <Box style={{ textAlign: 'center', marginLeft: '10px', marginBottom: '20px', marginRight: '10px', marginTop: '40px' }}>
                            <Typography style={{
                                fontWeight: 700,
                                fontFamily: "Poppins",
                                lineHeight: "28px",
                                fontSize: "20px",
                                alignItems: 'center',
                                color: "#1C2110"
                            }} >Failed to add Payment Method</Typography>
                            <Typography style={{
                                fontWeight: 400,
                                fontFamily: "Poppins",
                                lineHeight: "24px",
                                color: "#1C2110",
                                alignItems: 'center',
                                marginTop: '10px',
                                fontSize: "16px"
                            }}>There is an issue with the ACH transaction associated with your account. The payment method could not be added at this time. </Typography>
                        </Box>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ display: 'flex', justifyContent: 'center', paddingBottom: 30, marginTop: '30px' }}>
                        <Button onClick={this.handlCloseFailPaymentMethod} style={{
                            border: '1px solid #C2E176',
                            backgroundColor: '#F8FFE7',
                            width: '300px',
                            height: '56px',
                            fontSize: "16px",
                            fontWeight: 700,
                            textTransform: "none",
                            fontFamily: "Poppins",
                            lineHeight: "24px",
                            color: '#8CA74B'
                        }}>Cancel</Button>
                        <Button style={{
                            backgroundColor: '#8CA74B',
                            width: '300px',
                            height: '56px',
                            fontSize: "16px",
                            fontWeight: 700,
                            textTransform: "none",
                            fontFamily: "Poppins",
                            lineHeight: "24px",
                            color: 'white'
                        }} onClick={this.handleNewPaymentMethod} >Try again </Button>
                    </DialogActions>
                </Dialog>

                <Footer navigation={this.props.navigation} id={""} />
            </PaymentStyle >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const PaymentStyle = styled("div")({
    overflowX: 'hidden',
    "& .headerDiv": {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px',
        borderBottom: '1px solid #E7E5E4'
    },
    "& .breadcrumbsButton": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#57534E",
        "& .MuiButton-label": {
            textTransform: "none",
        },
    },
    "& .breadcrumbsText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#1C2110",
    },
    "& .profileDiv": {
        display: 'flex'
    },
    "& .errorMessage": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        color: '#DC2626',
        marginTop: '-12px',
        marginBottom: '15px'
    },
    "& .titleText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "40px",
        letterSpacing: "-0.5%",
        color: "#1C2110",
    },
    "& .titleSubText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "26px",
        color: "#000000",
        marginLeft: '60px',
        marginTop: '5px'
    },
    "& .paymentMethodTitle": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "28px",
        color: "#1C2110",
    },
    "& .paymentMethodDesc": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
    },
    '& .paymentHistoryDiv': {
        marginTop: '48px',
    },
    "& .paymentHistoryTitle": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "28px",
        color: "#1C2110",
    },
    "& .paymentHistoryDesc": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
    },
    "& .downloadBtn": {
        backgroundColor: "#8CA74B",
        borderRadius: '8px',
        height: '56px',
        width: '255px',
        "& .MuiButton-label": {
            fontFamily: "Poppins",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "24px",
            color: "#FFFFFF",
            textTransform: 'none'
        },
    },
    "& .historyBtn": {
        backgroundColor: "#8CA74B",
        borderRadius: '8px',
        height: '56px',
        padding: '16px 16px 16px 16px',
        "& .MuiButton-label": {
            fontFamily: "Poppins",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "24px",
            color: "#FFFFFF",
            textTransform: 'none'
        },
    },
    '& .billingAddressBox': {
        marginTop: '48px',
    },
    "& .billingTitle": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "28px",
        color: "#1C2110",
    },
    "& .billingDescription": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
    },
    "& .mainPaymentDiv": {
        marginLeft: '60px'
    },
    "& .paymentMethodDiv": {
        width: '100%',
        marginTop: '44px',
    },
    '& .formNameAndAddressBox': {
        display: 'flex',
        alignItems: 'center',
        columnGap: '16px',
    },
    '& .formNameBox': {
        width: '50%',
    },
    '& .formAddressBox': {
        width: '50%',
    },
    "& .formTextTitle": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
        marginBottom: '5px'
    },
    '& .actionBtnsBox': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        columnGap: '16px',
    },
    '& .cancelBtn': {
        height: '56px',
        width: '170px',
        backgroundColor: "#F8FFE7",
        marginTop: '32px',
        borderRadius: '8px',
        border: '1px solid #C2E176',
        "& .MuiButton-label": {
            cursor: 'pointer',
            fontSize: "16px",
            fontFamily: "Poppins",
            lineHeight: "24px",
            color: "#8CA74B",
            fontWeight: 700,
            textTransform: 'none',
        },
    },
    "& .saveBtn": {
        backgroundColor: "#8CA74B",
        borderRadius: '8px',
        height: '56px',
        width: '170px',
        marginTop: '32px',
        "& .MuiButton-label": {
            fontFamily: "Poppins",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "24px",
            cursor: 'pointer',
            color: "#FFFFFF",
            textTransform: 'none'
        },
    },
    "& .textField": {
        marginBottom: '20px'
    },
    "& .paymentBox1": {
        boxSizing: 'border-box',
        display: 'flex',
        height: '82px',
        width: '100%',
        borderRadius: '8px',
        backgroundColor: '#F8FFE7',
        border: '1px solid #C2E176',
        padding: '10px',
        marginBottom: '8px',
    },
    "& .paymentBox2": {
        boxSizing: 'border-box',
        display: 'flex',
        height: '82px',
        width: '100%',
        borderRadius: '8px',
        border: '1px solid #D6D3D1',
        padding: '10px',
        marginBottom: '8px',
    },
    "& .visaCardText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#000000",
    },
    "& .newPyamentBtn": {
        size: '10px',
        color: "#557C29",
        marginTop: '8px',
        cursor: 'pointer',
    },
    "& .newPyamentBtnText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "14px",
        color: "#557C29",
        textTransform: 'none',
        marginLeft: '6px',
    },
    "& .setDefaultText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        cursor: 'pointer',
        color: "#5D6063",
        "& .MuiButton-label": {
            textTransform: 'none'
        },
    },
    "& .setDefaultBtn": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#5D6063",
        cursor: 'pointer',
        "& .MuiButton-label": {
            textTransform: 'none'
        },
    },
    "& .line": {
        color: "#5D6063",
        margin: '0px 8px 0px 8px'
    }
});
const DialogMainContent = styled("div")({
    padding: '30px',
    height: 'auto',
    "& .titleTexts": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110"
    },
    "& .cancelBtn": {
        borderRadius: '8px',
        border: '1px solid #C2E176',
        backgroundColor: '#F8FFE7',
        width: '221px',
        height: '56px',
        '& .MuiButton-label': {
            textTransform: "none",
            fontSize: "16px",
            fontWeight: 700,
            color: '#8CA74B',
            fontFamily: "Poppins",
            lineHeight: "24px",
        }
    },
    "& .saveBtn": {
        width: '221px',
        backgroundColor: '#8CA74B',
        borderRadius: '8px',
        height: '56px',
        '& .MuiButton-label': {
            fontWeight: 700,
            fontSize: "16px",
            fontFamily: "Poppins",
            textTransform: "none",
            color: 'white',
            lineHeight: "24px",
        }
    },
    "& .headerBox": {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    "& .accountDetailsText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
        marginBottom: '20px'
    },
    "& .errorMessage": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        color: '#DC2626',
        marginTop: '-12px'
    }
});
// Customizable Area End
