import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";
import * as React from 'react';
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export interface PaymentRowProps {
  paymentDetails: any;
  handleSeeDetails: any;
}

interface BankAccount {
  bank_name: string;
  country: string;
  currency: string;
  last4?: string;
  routing_number: string;
  account_holder_name: string;
  account_holder_type: string;
}

interface PaymentMethodData {
  type?: 'card' | 'ach';
  brand?: string;
  last4?: string;
  identifier?: string;
  bankName?: string;
  bank_accounts?: BankAccount[];
}

// Customizable Area End


interface S {
  // Customizable Area Start
  selectedPaymentMethod: string;
  paymentMethod: 'creditCard' | 'bankTransfer';
  bankName: string;
  state: string;
  city: string;
  accountType: string;
  isPaymentModelOpen: boolean;
  addNewPaymentModal: boolean;
  openSuccessModal: boolean;
  orderNumber: string;
  amount: string;
  typeOfPlan: string;
  paymentDate: string;
  active: boolean;
  interval: string;
  storage: string;
  storageUnit: string;
  startDate: string;
  endDate: any;
  planName: any;
  cardNumber: string;
  paymentType: string;
  cardType: any;
  accountHolderName: string;
  bankCardName: string;
  accountTypeCard: string;
  routingNumber: string;
  accountNumber: string;
  isReactivateModal: boolean;
  isSuccessReactivateModal: boolean;
  subscriptionDetails: any;
  paymentDetails:any;
  methodModal: boolean;
  deleteModal: boolean;
  deleteSuccessfulModal: boolean;
  getPaymentID: string;
  sureDeleteModal: boolean;
  successFullModal: boolean;
  bankNumber: string;
  modelOpen: string;
  plans: any;
  selectedPlan: any;
  PaymentMethodData: PaymentMethodData[];
  checkMethodType: any;
  checkIndex: any;
  addedPaymentMethod: string;
  addedPaymentMethodFailed: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SubscriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserSubscriptionDetailsId: string = '';
  deleteCardPaymentApiCallId: any;
  deleteBankPaymentApiCallId: any;
  getCardDetailsApiCallId: any;
  getBankDetailsApiCallId: any;
  createSubscriptionApiCallId: string = '';
  reactivateSubscriptionApiCallId: string = '';
  createSubscriptionBankApiCallId: string = '';
  getPlansApiId: string = '';
  deleteCardBankApiCallId: string = '';
  getBankOrCardDetailsApiCallId: string = '';
  getPaymentsDetailsApiId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedPaymentMethod: 'VisaCard',
      paymentMethod: 'creditCard',
      bankName: "",
      state: "",
      city: "",
      accountType: "",
      isPaymentModelOpen: false,
      addNewPaymentModal: false,
      openSuccessModal: false,
      orderNumber: '',
      amount: '',
      typeOfPlan: '',
      paymentDate: '',
      active: false,
      interval: '',
      storage: '',
      storageUnit: '',
      startDate: '',
      endDate: '',
      planName: '',
      cardNumber: '',
      paymentType: '',
      bankNumber: '',
      isReactivateModal: false,
      cardType: '',
      accountHolderName: '',
      bankCardName: '',
      accountTypeCard: '',
      routingNumber: '',
      accountNumber: '',
      isSuccessReactivateModal: false,
      subscriptionDetails: [],
      paymentDetails:[],
      methodModal: false,
      deleteModal: false,
      deleteSuccessfulModal: false,
      getPaymentID: '',
      sureDeleteModal: false,
      successFullModal: false,
      modelOpen: '',
      plans: [],
      selectedPlan: null,
      PaymentMethodData: [],
      checkMethodType: "",
      checkIndex: 0,
      addedPaymentMethod: '',
      addedPaymentMethodFailed: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.createSubscriptionBankApiCallId ) {
      this.handleSubscriptionCreation('bankTransfer', responseJson);
    }
    else if (apiRequestCallId === this.createSubscriptionApiCallId) {
      this.handleSubscriptionCreation('creditCard', responseJson);
    } 
    else if (apiRequestCallId === this.getUserSubscriptionDetailsId) {
      this.handleUserSubscriptionDetails(responseJson);
    }
    else if (apiRequestCallId === this.getBankOrCardDetailsApiCallId) {
      this.handleDeleteCardOrBank(responseJson);
    }
    else if (apiRequestCallId === this.getPlansApiId) {
      this.setState({ plans: responseJson });
    } else if (apiRequestCallId === this.reactivateSubscriptionApiCallId) {
      this.handleReactivateSubs(responseJson);
    }
    else if (apiRequestCallId === this.deleteCardBankApiCallId) {
      this.handleDeleteCardBankResponse(responseJson);
    }
    else if (apiRequestCallId === this.getPaymentsDetailsApiId) {
      this.setState({ paymentDetails: responseJson });
      console.log(this.state.paymentDetails,"paymentDetails");
      
    }
    // Customizable Area End
  }

  // Customizable Area Start  
  async componentDidMount() {
    this.getUserSubscriptionDetails();
    this.getPlanDetails();
    this.getPaymentsDetails();
    this.getBankOrCardDetails();
  }

  handleUserSubscriptionDetails(responseJson: { subscriptions_history: string | any[]; }) {
    if (responseJson.subscriptions_history && responseJson.subscriptions_history.length > 0) {
      const subscriptionsHistory = responseJson.subscriptions_history[0];
      this.setState({
        subscriptionDetails: Array.isArray(subscriptionsHistory) ? subscriptionsHistory : [subscriptionsHistory],
        amount: subscriptionsHistory.amount,
        typeOfPlan: subscriptionsHistory.plan_name,
        interval: subscriptionsHistory.interval,
        active: subscriptionsHistory.active,
        storage: subscriptionsHistory.storage,
        storageUnit: subscriptionsHistory.storage_unit,
        startDate: subscriptionsHistory.start_date,
      });
    }
  }

  handleSubscriptionCreation(paymentMethod: any, responseJson: { errors: string | any[]; }) {
    if (responseJson?.errors && responseJson?.errors?.length !== 0) {
      this.setState({
        isPaymentModelOpen: true,
        addNewPaymentModal: false,
        addedPaymentMethodFailed: paymentMethod
      });
    } else {
      this.getUserSubscriptionDetails();
      this.setState({
        addNewPaymentModal: false,
        openSuccessModal: true,
        addedPaymentMethod: paymentMethod
      });
    }
  }

  upiDetialsSchema = Yup.object({
    accountHolderName: Yup.string()
      .required("Please enter your Full Name")
      .matches(
        /^[A-Za-z]+(?:\.| [A-Za-z]+)*(?: [A-Za-z]+)*(?: \.[A-Za-z]+)*\.?$/,
        'Invalid full name. Full name must contain only alphabets.'
      ),
    bankName: Yup.string().required("Please select your bank name"),
    accountNumber: Yup.string().required("Please enter your account number"),
    accountType: Yup.string().required("Please select your bank account type"),
    routingNumber: Yup.string().required("Please enter your routing number"),
  });

  getOrdinal = (day: string) => {
    if (day === '0' || day === '') {
      return '';
    } else if (day === '1' || day === '21' || day === '31') {
      return day + 'st';
    } else if (day === '2' || day === '22') {
      return day + 'nd';
    } else if (day === '3' || day === '23') {
      return day + 'rd';
    } else {
      return day + 'th';
    }
  }

  handleBoxClick = (plan: any) => {
    this.setState({ selectedPlan: plan });
  }

  handleNavigateManage = () => {
    this.props.navigation.navigate("ManageSubscription");
  }

  handlePaymentMethodChange = (event: any) => {
    this.setState({
      selectedPaymentMethod: event.target.value,
    });
  };

  handleEditModal = (type: any, index: any) => {
    console.log("response 330", type);

    this.setState({ methodModal: true, checkMethodType: type, checkIndex: index });
    setTimeout(() => {

    }, 1000);



  }

  handleToggleButton = (
    event: React.MouseEvent<HTMLElement>,
    newPaymentMethod: 'creditCard' | 'bankTransfer'
  ) => {
    if (newPaymentMethod !== null) {
      this.setState({ paymentMethod: newPaymentMethod });
    }
  };

  handleAccountType = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value } = event.target || { value: undefined };
    this.setState({ accountType: value as string });
  }

  handleBankName = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value } = event.target || { value: undefined };
    this.setState({ bankName: value as string || '' });
  };

  handleOpenModal = () => {
    this.setState({ addNewPaymentModal: false, openSuccessModal: true });
  };


  handleNavigateView = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "PaymentHistory");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg)
  }

  handleSeeDetails = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "PaymentDetails");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg)
  }

  handleCloseModal = () => {
    this.setState({ addNewPaymentModal: false, openSuccessModal: false });
  };

  handleNewPaymentMethod = () => {
    this.setState({ addNewPaymentModal: true, isPaymentModelOpen: false });
  }

  handlCloseFailPaymentMethod = () => {
    this.setState({ isPaymentModelOpen: false });
  }

  handleCloseNewPaymentMethod = () => {
    this.setState({ addNewPaymentModal: false });
  }

  handleReactivateModal = () => {
    this.setState({ isReactivateModal: true })
  }

  handleCloseReactivateModal = () => {
    this.setState({ isReactivateModal: false })
  }

  handleCloseSuccessReactivateModal = () => {
    this.setState({ isSuccessReactivateModal: false })
  }

  handleCardCloseModal = () => {
    this.setState({ methodModal: false });
  };

  handleDeletePaymentMethodModal = () => {
    this.setState({ methodModal: false, sureDeleteModal: true });
  }

  handleDeleteCloseModal = () => {
    this.setState({ sureDeleteModal: false, deleteModal: false });
  }

  handleDeleteSuccessModal = () => {
    this.setState({ deleteModal: false, deleteSuccessfulModal: true });
  }

  handleDeleteSuccessCloseModal = () => {
    this.setState({ deleteSuccessfulModal: false });
  }

  handleSuccessModal = () => {
    this.setState({ sureDeleteModal: false, successFullModal: true })
  }

  handleSuccessCloseModal = () => {
    this.setState({ successFullModal: false })
  }

  handleSureDeleteModal = () => {
    this.setState({ sureDeleteModal: false })
  }


  getUserSubscriptionDetails = () => {
    let token = localStorage.getItem("token");
    const header = { token: token };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserSubscriptionDetailsId = requestMsg.messageId;
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_subscriptionbilling/get_subscription`);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true
  }

  getBankOrCardDetails = () => {

    let token = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBankOrCardDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteBankOrCardEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };



  createSubscriptionApiCall = (id: any) => {
    let token = localStorage.getItem("token");
    const header = { "Content-Type": configJSON.validationApiContentType, token: token };
    const body = { "plan": this.state.typeOfPlan, "token_stripe": id }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createSubscriptionApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_subscriptionbilling/attach_card");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  };

  createSubscriptionAchApiCall = (values: any) => {
    let token = localStorage.getItem("token");
    const account_holder_name = values.accountHolderName;
    const account_holder_type = values.accountType;
    const routing_number = values.routingNumber;
    const account_number = values.accountNumber;
    const bank_name = values.bankName;
    const params = { account_holder_name, account_holder_type, routing_number, account_number, bank_name };

    const convertString = (params: any) => {
      return Object.keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
    };

    const queryString = convertString(params);
    const header = { "Content-Type": configJSON.validationApiContentType, token: token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createSubscriptionBankApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_subscriptionbilling/attach_bank_account?${queryString}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  };

  getPlanDetails = () => {
    let token = localStorage.getItem("token");
    const header = { token: token };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getPlansApiId = requestMsg.messageId;
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_subscriptionbilling/plan_and_price`);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true
  }

  handleReactivateSubscription = () => {
    const { selectedPlan } = this.state;
    if (selectedPlan) {
      this.handleReactivatePlan(selectedPlan);
    }
  }

  handleReactivatePlan = ((plan: any) => {
    let token = localStorage.getItem("token");
    const params = plan.product_name;
    const header = { token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.reactivateSubscriptionApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_subscriptionbilling/reactivate_subscription?plan=${params}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PATCH");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  })

  handleBankApi = (responseJson: any) => {
    if (responseJson.message == "Cannot delete the last bank") {
      this.setState({ methodModal: false, deleteModal: true });
    }
    else {
      this.setState({ sureDeleteModal: false, successFullModal: true })
    }
  }

  handleCardApi = (responseJson: any) => {
    if (responseJson.error == "Cannot delete the last payment method. At least one card or bank account must remain.") {
      this.setState({ methodModal: false, deleteModal: true });
    }
    else {
      this.setState({ sureDeleteModal: false, successFullModal: true })
    }
  }


  getDeleteCardAch = (getPaymentID: any) => {

    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCardBankApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteCardAchEndPoint + "?payment_id=" + getPaymentID
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleReactivateSubs(responseJson: any) {
    const subscriptionsHistory = responseJson.subscriptions_history[0];
    this.setState({
      isReactivateModal: false,
      isSuccessReactivateModal: true,
      endDate: subscriptionsHistory.end_date,
      planName: subscriptionsHistory.plan_name
    });
    this.getUserSubscriptionDetails();
    this.getPlanDetails();
  }



  handleDeleteCardBankResponse(responseJson: any) {
    if (!responseJson.error) {
      this.getUserSubscriptionDetails();
      this.handleSuccessModal();
    } else {
      this.handleCardApi(responseJson);
    }
  }

  handleDeleteCardOrBank(responseJson: any) {
    const { checkMethodType } = this.state;

    const match = checkMethodType.match(/(?:Card0_|ACH0_)(\d+)/);
    const checkIndex = match ? parseInt(match[1], 10) : -1;

    if (checkIndex === -1) {
      console.log('Invalid method type format');
      return;
    }
    const dataKey = checkMethodType.startsWith("Card0_") ? 'cards' :
      checkMethodType.startsWith("ACH0_") ? 'bank_accounts' : null;

    if (dataKey && responseJson[dataKey] && responseJson[dataKey][checkIndex]) {
      const id = responseJson[dataKey][checkIndex].id;
      this.getDeleteCardAch(id);
    }
  }

  getPaymentsDetails = () => {
    let token = localStorage.getItem("token");
    const header = { token: token };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getPaymentsDetailsApiId = requestMsg.messageId;
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_subscriptionbilling/get_transactions`);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true
  }




  // Customizable Area End
}