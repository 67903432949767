// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import generatePDF from 'react-to-pdf';

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
export interface PaymentMethodProps {
    paymentMethod: {
      type: string;
      brand?: string;
      last4?: string;
    } | null; 
  }
// Customizable Area End


interface S {
    // Customizable Area Start
    subscriptionDetails: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PaymentHistoryController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    targetRef = React.createRef<HTMLDivElement>();
    getUserPaymentHistoryDetailsId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceDataMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            subscriptionDetails: [],
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson) {
            if (apiRequestCallId === this.getUserPaymentHistoryDetailsId) {
                this.setState({
                    subscriptionDetails: responseJson,
                });
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start  
    async componentDidMount() {
        this.getUserHistoryDetails();
    }

    handleSubscription = () => {
        this.props.navigation.navigate("PaymentSettings");
    }

    handleDownload = () => {
        generatePDF(this.targetRef, { filename: 'payment-history.pdf' });
    };

    getUserHistoryDetails = () => {
        let token = localStorage.getItem("token");
        const header = { token: token };
        const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getUserPaymentHistoryDetailsId = requestMsg.messageId;
        requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_subscriptionbilling/get_transactions`);
        requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
        runEngine.sendMessage(requestMsg.id, requestMsg);
        return true
    }

    handleSeeDetails = () => {
        this.props.navigation.navigate("PaymentDetails")
    }







    // Customizable Area End
}

