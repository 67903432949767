import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";

type FileType = "photo" | "video";
interface Folder {
  name: string;
  createdDate: string;
  filesCount: number;
  isChecked: boolean;
}
interface Person {
  name: string;
  count: number;
  avatar: string;
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  authToken: any;
  showDropdown: boolean;
  isGroupsExpanded: boolean;
  page: number;
  totalPages: number;
  isPhotoExpanded: boolean;
  isPeopleExpanded: boolean,
  isMemoriesExpanded: boolean,
  isFamilyTreeExpanded: boolean,
  isRecipesExpanded: boolean,
  showDeleteModal: boolean;
  showRecoverModal: boolean;
  isOpen: boolean;
  selectedOption: string;
  photosdata: any;
  selectedFolder: string;
  isDropdownOpen: boolean;
  selectType: string;
  selectedFileType: FileType;
  anchorEl: HTMLElement | null;
  isAllSelected: boolean;
  checkbox1: boolean;
  checkbox2: boolean;
  checkbox3: boolean;
  folders: Folder[];
  selectedTab: string;
  selectedPeople: string[];
  open: boolean;
  role: string;
  menuItems:  HTMLElement | null;
  dateOpen: boolean;
  year: string;
  month: string;
  datePopOver: HTMLElement | null;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  dropdownRef: React.RefObject<HTMLDivElement>;
  getTrashDataApiCallId: string = "";
  filterAscendingDataApiCallId: string = "";
  deleteAllDataFromTrashApiCallId: string = "";
  restoreSingleFileApiCallId: string = "";
  deleteSingleFileApiCallId: string = "";
  restoreAllFileApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      authToken: false,
      showDropdown: false,
      isGroupsExpanded: false,
      page: 1,
      totalPages: 2,
      isPhotoExpanded: false,
      isPeopleExpanded: false,
      isMemoriesExpanded: false,
      isFamilyTreeExpanded: false,
      isRecipesExpanded: false,
      showDeleteModal: false,
      showRecoverModal: false,
      isOpen: false,
      selectedOption: "Most recent",
      photosdata: [],
      selectedFolder: 'Miami Beach',
      isDropdownOpen: false,
      selectType: "",
      selectedFileType: "photo",
      anchorEl: null,
      isAllSelected: false,
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      folders: [
        { name: 'Birthday', createdDate: '10 Mar', filesCount: 20, isChecked: false },
        { name: 'Miami Beach', createdDate: '10 Mar', filesCount: 74, isChecked: false },
      ],
      selectedTab: 'photos',
      selectedPeople: [],
      open: false,
      role: 'Contributor',
      menuItems: null,
      dateOpen: false,
      year: '',
      month: '',
      datePopOver: null,
    };
    this.dropdownRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.checkAuthToken();
    this.getBankSubscriptionApiCall();
    this.filterDataAscendingApiCall();
    document.addEventListener('mousedown', this.handleClickOutside);
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
         this.handleApiCalls(webApiRequestCallId, webResponseJson);
         if(webApiRequestCallId === this.deleteSingleFileApiCallId){
          this.setState({ photosdata: webResponseJson });
        }
        
        else if(webApiRequestCallId === this.restoreAllFileApiCallId){
          this.setState({ photosdata: webResponseJson });
        }
        else if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            dashboardData: webResponseJson.data.attributes.sub_attributres,
            totalCandidates: webResponseJson.data.attributes.total_candidates,
            type: webResponseJson.data.type,
            errorMsg: "",
            loading: false
          });
        }
      } else if (webResponseJson && webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            errorMsg: webErrorReponse,
            loading: false
          });
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  onLoginClick = () => {
    this.props.navigation.navigate("Login")
  }

  onRegisterClick = () => {
    this.props.navigation.navigate("Signuplogin")
  }

  checkAuthToken = () => {
    const checkToken = localStorage.getItem("token");
    if (checkToken) {
      this.setState({ authToken: true });
    }
  }

  toggleDropdown = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };


  handleGroupExpand = () => {
    this.setState(prevState => ({
      isGroupsExpanded: !prevState.isGroupsExpanded
    }));
  };
  handlePageChange = (event: null, value: number) => {
    this.setState({ page: value });
  };


  handlePhotoExpand = () => {
    this.setState(prevState => ({
      isPhotoExpanded: !prevState.isPhotoExpanded
    }));
  };

  handlePeopleExpand = () => {
    this.setState(prevState => ({
      isPeopleExpanded: !prevState.isPeopleExpanded
    }));
  };

  handleMemoriesExpand = () => {
    this.setState(prevState => ({
      isMemoriesExpanded: !prevState.isMemoriesExpanded
    }));
  };

  handleFamilyTreeExpand = () => {
    this.setState(prevState => ({
      isFamilyTreeExpanded: !prevState.isFamilyTreeExpanded
    }));
  };

  handleRecipesExpand = () => {
    this.setState(prevState => ({
      isRecipesExpanded: !prevState.isRecipesExpanded
    }));
  };

  handleClickOutside = (event: MouseEvent) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target as Node)) {
      this.setState({ showDropdown: false });
    }
  };

  async componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  openModal = () => {
    this.setState({ showDeleteModal: true });
  };

  closeModal = () => {
    this.setState({ showDeleteModal: false });
  };

  openRecoverModal = () => {
    this.handleSelectAll()
    this.setState({ showRecoverModal: true });
  };

  closeRecoverModal = () => {
    this.handleSelectAll()
    this.setState({ showRecoverModal: false });
  };


  handleDeleteAll = () => {
    this.deleteAllDataFromTrashApiCall();
    this.closeModal();
    
  };

  handleRecover = () => {
    this.restoreAllFileApiCall();
    this.closeModal();
  }


  toggleDropdownOpen = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  handleSelect = (option: string) => {
    this.setState({ selectedOption: option, isOpen: false });
  };

  getBankSubscriptionApiCall = () => {

    let token = localStorage.getItem("token");

    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: token
    };    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTrashDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.getTrashDataEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  filterDataAscendingApiCall = () => {
    let token = localStorage.getItem("token");

    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: token
    };    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.filterAscendingDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.getAscendingApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  deleteAllDataFromTrashApiCall = () => {
    let token = localStorage.getItem("token");

    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: token
    };    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteAllDataFromTrashApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.deleteAllDataFromTrashApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.deleteAllDataApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  restoreSingleFileApiCall = () => {
    let token = localStorage.getItem("token");

    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: token
    };    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.restoreSingleFileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.restoreSingleDataFromTrashApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.restoreSingleDataApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleChange = (event:any) => {
    const { value } = event.target;
    this.setState({
      selectType: value, 
    });
  };


  deleteSingleFileApiCall = (mediaType:any) => {
    let token = localStorage.getItem("token");

    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: token
    };    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteSingleFileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.deleteSingleFileApiEndPoint + `?media_type=${mediaType}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.deleteAllDataApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getMediaType = () => {
    const isVideo = this.state.selectedFileType === "video";
    return isVideo ? "video" : "photo";
  }



  restoreAllFileApiCall = () => {
    let token = localStorage.getItem("token");

    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: token
    };    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.restoreAllFileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.restoreAllApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.restoreAllApiMethodtype
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
};

handleMenuClose = () => {
  this.setState({ anchorEl: null });
};

handleSelectAll = () => {
  this.setState((prevState) => {
    const isAllSelected = !prevState.isAllSelected;
    const updatedFolders = prevState.folders.map((folder) => ({
      ...folder,
      isChecked: isAllSelected, 
    }));
    const updatedPhotos = prevState.photosdata.map((photo: any) => ({
      ...photo,
      isChecked: isAllSelected,
    }));

    return {
      folders: updatedFolders,
      photosdata: updatedPhotos,
      isAllSelected,
    };
  });
};


getSelectedCount = () => {
  const { folders } = this.state;
  const selectedCount = folders.filter(folder => folder.isChecked).length;
  return selectedCount;
};

areAllFilesSelected = () => {
  return this.state.folders.every(folder => folder.isChecked);
};


handlePhotoCheckboxChange = (index: number) => {
  this.setState((prevState) => {
    const updatedPhotos = Array.isArray(prevState.photosdata) ? [...prevState.photosdata] : [];
    if (updatedPhotos[index]) {
      updatedPhotos[index].isChecked = !updatedPhotos[index].isChecked;
    }

    return { photosdata: updatedPhotos };
  });
};


handleFolderCheckboxChange = (index: number) => {
  this.setState((prevState) => {
    const updatedFolders = [...prevState.folders];
    updatedFolders[index].isChecked = !updatedFolders[index].isChecked; 
    return { folders: updatedFolders };
  });
};


handleGetTrashDataResponse(webResponseJson: any) {
  if(webResponseJson.message !== "No images or videos in trash."){
    this.setState({ photosdata: webResponseJson });
  }
}


handleFilterAscendingDataResponse(webResponseJson: any) {
  if(!webResponseJson.message){
    this.setState({ photosdata: webResponseJson });
  }
}

handleDeleteAllDataResponse(webResponseJson: any) {
  this.setState({ photosdata: webResponseJson });
}

handleRestoreSingleFileResponse(webResponseJson: any) {
  this.setState({ photosdata: webResponseJson });
}


handleApiCalls( webApiRequestCallId:any, webResponseJson:any){
  
  if(webApiRequestCallId === this.getTrashDataApiCallId) {
    this.handleGetTrashDataResponse(webResponseJson);
  }

  else if(webApiRequestCallId === this.filterAscendingDataApiCallId){
    this.handleFilterAscendingDataResponse(webResponseJson);        
  }

  else if(webApiRequestCallId === this.deleteAllDataFromTrashApiCallId){
    this.handleDeleteAllDataResponse(webResponseJson);
  }
  
  else if(webApiRequestCallId === this.restoreSingleFileApiCallId){
    this.handleRestoreSingleFileResponse(webResponseJson);
  }

}

handleTabChange = (tab: string) => {
  this.setState({ selectedTab: tab });
};

handleClick = (event: React.MouseEvent<HTMLElement>) => {
  this.setState({ anchorEl: event.currentTarget });
};

handleClose = () => {
  this.setState({ anchorEl: null });
};

handleToggle = (person: string) => {
  this.setState((prevState) => ({
    selectedPeople: prevState.selectedPeople.includes(person)
      ? prevState.selectedPeople.filter((p) => p !== person)
      : [...prevState.selectedPeople, person],
  }));
};

handleClickOpen = () => {
  this.setState({ open: true });
};


handleCloseProfileModal = () => {
  this.setState({ open: false });
};

handleMenuItemClick = (event:any) => {
  this.setState({ menuItems: event.currentTarget });
};

handleMenuItemClose = () => {
  this.setState({ menuItems: null });
};


handleYearChange = (event: any) => {
  this.setState({ year: event.target.value });
};
handleMonthChange = (event: any) => {
  this.setState({ month: event.target.value as string });
};


handleOpenPopover = (event:any) => {
  this.setState({ datePopOver: event.currentTarget });
};

handleClosePopover = () => {
  this.setState({ datePopOver: null });
};

  // Customizable Area End
}
