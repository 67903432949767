import React from "react";

import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import { Logo, loginImage } from "./assets";
import { Formik } from "formik";
import { Alert } from '@material-ui/lab';
// Customizable Area End

import LoginController, {
  Props,
} from "./LoginController.web";
import { ErrorMessage } from "formik";

export default class Login extends LoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <LoginStyle>
        <Box className="mainDiv">
          <Box className="mainContainer">
            <Box className="formContainer">
              <Box className="imageLogo">
                <img style={{ height: '105px', width: '209px' }} src={Logo} alt="Logo" />
              </Box>
              <Formik
                enableReinitialize
                data-test-id="formikForm"
                initialValues={{
                  email: this.state.email || "",
                  password: this.state.password || "",
                  rememberMe: this.state.rememberMe
                }}
                validationSchema={this.loginSchema}
                onSubmit={values => {
                  this.submitLoginDetails(values.email, values.password, values.rememberMe);
                }}
              >
                {({ handleSubmit, handleChange, values, isSubmitting }) => (
                  <form className="formContainer" onSubmit={handleSubmit}>
                    {this.state.apiError && (
                      <Alert
                        className="alertContainer"
                        severity="error"
                        icon={false}
                        style=
                        {{
                          display: 'block',
                          marginTop: '32px'
                        }}
                      >
                        {this.state.apiError}
                      </Alert>
                    )}
                    <Typography className="loginTitle">Welcome back!</Typography>
                    <Typography className="textFieldTitles">E-mail</Typography>
                    <TextField
                      data-test-id="emailField"
                      type="email"
                      className="placeholderTexts"
                      value={values.email}
                      onChange={handleChange}
                      autoComplete="off"
                      name="email"
                      placeholder="Your e-mail"
                      variant="outlined"
                      fullWidth
                    />
                    <ErrorMessage name="email" component="div" className="errorMessages" />
                    <Typography className="textFieldTitles">Password</Typography>
                    <TextField
                      data-test-id="passwordField"
                      className="placeholderTexts"
                      onChange={handleChange}
                      value={values.password}
                      autoComplete="off"
                      fullWidth
                      placeholder="Your password"
                      name="password"
                      type={!this.state.showPassword ? "password" : "text"}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={this.handleClickShowPassword}
                              edge="end"
                              data-test-id="showPasswordIconButton"
                              id="passwordConfirmationInput"
                            >
                              {this.renderIconShowPassword(
                                !this.state.showPassword
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage name="password" component="div" className="errorMessages" />
                    <Box className="conditionContainer">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.rememberMe}
                            onChange={handleChange}
                            name="rememberMe"
                            style={{ color: "#557C29" }}
                          />
                        }
                        label={
                          <Typography>
                            <span className="rememberText">Remember me</span>
                          </Typography>
                        }
                      />
                      <Button onClick={this.handleForgotBtn} data-test-id="forgotPasswordBtn" className="forgotPasswordBtn">Forgot password?</Button>
                    </Box>
                    <Button data-test-id="loginBotton" type="submit" className="loginBotton" variant="contained" color="primary" fullWidth disabled={isSubmitting}>
                      Log in
                    </Button>

                    <Box className="accountcontainer">
                      <Typography className="accountTextTitle">Don’t have an account?</Typography>
                      <Button data-test-id="signupBtn" onClick={this.handleNavigateSignup} className="signupBtn">Sign up</Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
      </LoginStyle>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const LoginStyle = styled("div")({
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  overflow: "hidden",
  overflowY: "auto",

  "& .mainDiv": {
    background: `url(${loginImage})`,
    backgroundRepeat: "no-repeat",
    height: "100%",
    width: "100%",
  },
  "& .alertContainer": {
    borderRadius: '4px',
    border: '0px, 0px, 0px, 4px',
    borderLeft: '4px solid #DC2626',
    padding: "12px, 16px, 12px, 16px",
    fontSize: "12px",
    foneFamily: "Poppins",
    fontWeight: 400,
    color:'#DC2626'
  },

  "& .mainContainer": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "40px 40px 5px 40px",
    marginLeft: "55%",
    zIndex: 0,
    backgroundColor: "white",
    borderRadius: "20px",
    width: "420px",
    overflow: "hidden",
    marginTop: '10%',
    "@media (max-width: 480px)": {
      marginLeft: "1rem",
      marginRight:"1rem",
      width:"auto",
    },
    "@media only screen and (min-device-width: 480px) and (max-device-width: 820px)":{
      marginLeft: "1rem",
      marginRight:"1rem",
      width:"auto",
    }
  },
  "& .formContainer": {
    width: '100%',
    height: "100%",
  },
  "& .imageLogo": {
    padding: "0px, 0px, 0px, 40px",
    textAlign: "center",
    marginBottom: '32px',
  },
  "& .loginTitle": {
    marginTop: "32px",
    marginBottom: "40px",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "36px",
    lineHeight: "32px",
    letterSpacing: "-0.1%",
    color: "#1C2110",
    "@media (max-width: 369px)": {
      fontSize: "30px",
    },
  },
  "& .loginBotton": {
    borderRadius: '8px',
    height: '56px',
    Gap: '8px',
    padding: '16px',
    fontWeight: 700,
    marginTop: '24px',
    lineHeight: "24px",
    fontSize: "16px",
    backgroundColor: '#8CA74B',
    "& .MuiButton-label": {
      textTransform: "none",
      fontWeight: 700,
      fontSize: "16px",
    },
  },
  "& .errorMessages": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "18px",
    color: '#DC2626',
  },
  "& .accountcontainer": {
    alignItems: "center",
    display: "flex",
    marginTop: "16px",
    justifyContent: "center",
  },
  "& .textFieldTitles": {
    fontSize: "16px",
    fontWeight: 700,
    color: "#1C2110",
    lineHeight: "24px",
    marginBottom: '4px',
    marginTop: '16px',
    fontFamily: "Poppins",
  },
  "& .placeholderTexts": {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Poppins",
    color: "#94A3B8"
  },
  "& .conditionContainer": {
    marginTop: '5px',
    display: "flex",
    justifyContent: "space-between"
  },
  "& .rememberText": {
    color: "#1C2110",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    fontStyle: "Poppins",
    "@media (max-width: 369px)": {
      fontSize: "13px",
    },
  },
  "& .forgotPasswordBtn": {
    color: "#557C29",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    fontStyle: "Poppins",
    "& .MuiButton-label": {
      textTransform: "none",
      fontWeight: 700,
      fontSize: "13px",
    },
    "@media (max-width: 369px)": {
      fontSize: "12px",
    },
  },
  "& .accountTextTitle": {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1C2110",
    "@media (max-width: 369px)": {
      fontSize: "15px",
    },
  },
  "& .signupBtn": {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#557C29",
    fontStyle: "Poppins",
    "& .MuiButton-label": {
      textTransform: "none",
    },
    "@media (max-width: 369px)": {
      fontSize: "15px",
    },
  },
});
// Customizable Area End