import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import ShareIcon from "@mui/icons-material/Share";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import SentimentVerySatisfiedOutlinedIcon from '@material-ui/icons/SentimentVerySatisfiedOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import { Recipes, Storage, recipesImg, storeImg } from "../../blocks/navigationmenu/src/assets";

interface MenuItem {
  text: string;
  subItems: string[];
  icon: React.ReactNode;
  root:string;
}

interface SideDrawerProps {
  initialSelectedItem?: string; 
  navigation?:any;
}

const menuItems: MenuItem[] = [
  { text: "Favorites", subItems: [], icon: <FavoriteBorderIcon />,root:"" },
  { text: "Photos", subItems: [], icon: <CameraAltOutlinedIcon /> ,root:""},
  { text: "People", subItems: ["Family", "Friends"], icon: <PeopleOutlinedIcon />,root:"" },
  { text: "Moments", subItems: ["Places", "Memories"], icon: <SentimentVerySatisfiedOutlinedIcon />,root:"" },
  { text: "Family Tree", subItems: [], icon: <AccountTreeOutlinedIcon />,root:"" },
  { text: "Recipes", subItems: [], icon: null,root:"" },
  { text: "Sharing", subItems: [], icon: <ShareIcon />,root:"SharingDashboard" },
  { text: "Storage", subItems: [], icon: null,root:"" },
  { text: "Trash", subItems: [], icon: <DeleteOutlineIcon />,root:"Trash" },
];

export default function SideDrawer({ initialSelectedItem,navigation }: SideDrawerProps) {
  const [selectedItem, setSelectedItem] = React.useState<string | null>(initialSelectedItem || null);
  const [showPeopleSubItems, setShowPeopleSubItems] = React.useState(false);
  const [showMomentsSubItems, setShowMomentsSubItems] = React.useState(false);

  const handleItemClick = (itemText: string,root: string) => {
    setSelectedItem(itemText);
    if (itemText === "People") {
      setShowPeopleSubItems(!showPeopleSubItems);
    } else if (itemText === "Moments") {
      setShowMomentsSubItems(!showMomentsSubItems);
    }
    else if (itemText === "Trash" || itemText === "Sharing") {
      navigation ? navigation.navigate(root) : window.location.replace(root)
    }
  };

  const handleSubItemClick = (subText: string) => {
    setSelectedItem(subText);
  };

  const mainItemStyle = (text: string): React.CSSProperties => ({
    color: selectedItem === text ? "#fff" : "#1C2110",
    backgroundColor: selectedItem === text ? "#557C29" : "white",
    borderRadius: selectedItem === text ? "4px" : "0px",
  });

  return (
    <Box sx={{ width: 250, background: "white" , boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.07)", height: "100vh"}}  >
      <List style={{ padding: '20px' }}>
        {menuItems.map(({ text, subItems, icon,root }) => (
          <React.Fragment key={text}>
            <ListItem disablePadding sx={{ position: "relative" }}>
              <ListItemButton
                onClick={() => handleItemClick(text,root)}
                sx={{
                  ...mainItemStyle(text),
                  marginTop: "12px",
                  "&:hover": {
                    backgroundColor: selectedItem === text ? "#557C29" : "white",
                    opacity: 1,
                    height:'40px'

                  },
                }}
              >
                <ListItemIcon sx={{ color: selectedItem === text ? "#fff" : "#1C2110" }}>
                  {text === "Recipes" && (
                    <img
                      src={selectedItem === "Recipes" ? Recipes : recipesImg}
                      style={{ color: "#1C2110" }}
                      alt="Recipes Icon"
                    />
                  )}
                  {text === "Storage" && (
                    <img
                      src={selectedItem === "Storage" ? Storage : storeImg}
                      style={{ color: "#1C2110" }}
                      alt="Recipes Icon"
                    />
                  )}
                  {icon}
                </ListItemIcon>
                <Typography style={{marginLeft:'-15px'}}
                  variant="body1"
                  sx={{
                    fontSize: selectedItem === text ? 16 : 14,
                    fontWeight: selectedItem === text ? 700 : 400,
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {text}
                </Typography>
              </ListItemButton>
            </ListItem>
            {subItems.length > 0 &&
              ((text === "People" && showPeopleSubItems) ||
                (text === "Moments" && showMomentsSubItems)) && (
                <List>
                  {subItems.map((subText) => (
                    <ListItem key={subText} disablePadding>
                      <ListItemButton
                        onClick={() => handleSubItemClick(subText)}
                        sx={{
                          borderLeft: "1px solid #fff",
                          color: selectedItem === subText ? "#fff" : "#557C29",
                          paddingLeft: "30px",
                          marginLeft: "25px",
                          borderRadius: selectedItem === subText ? "4px" : "0px",
                          backgroundColor: selectedItem === subText ? "#557C29" : "white",
                          "&:hover": {
                            backgroundColor: selectedItem === subText ? "#557C29" : "white", 
                            borderRadius: "4px",
                            height:'40px'
                          },
                        }}>
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: selectedItem === subText ? 16 : 14,
                            fontWeight: selectedItem === subText ? 700 : 400,
                            fontFamily: "Poppins, sans-serif",
                            marginTop: "8px",
                          }}
                        >
                          {subText}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
}
